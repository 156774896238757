<template>
	<tr>
		<td class="text-xs font-light" style="width: 10rem">
			<span class="">
				{{ data.number_in_app }}
			</span>
		</td>
		<td class="text-xs font-light w-52" style="width: 17rem">
            <span v-if="data.customer">

                {{
					$services.helpers.capitalizeString(data.customer.name)
				}}
            </span>
		</td>
		<td class="text-xs font-light w-52" style="width: 17rem">
            <span v-if="data.subscription">

                {{
					getSubscription()
				}}
            </span>
		</td>
		<td class="text-xs font-light w-16" style="width: 11rem">{{ data.total_price }}</td>
		<td class="text-xs font-light w-12" style="width: 11rem">{{ data.currency }}</td>
		<td class="text-xs font-light" style="width: 15rem">{{ parseDateTime(data.date_updated) }}</td>
		<td class="text-xs w-36" style="width: 11rem;">
			<span v-if="data">
				<span v-if="data.number_in_accounting" class="badge badge-success px-2 w-3/4 rounded">
					{{ $t('synced') }}
				</span>
				<span v-else class="badge badge-danger w-3/4 px-2 rounded">
					{{ $t('notSync') }}
				</span>

			</span>
		</td>
		<td>
			<span v-if="!data.number_in_accounting">
                  <div class="w-40 md:w-60" slot="content">
                        {{ data.reason }}
                    </div>
            </span>
		</td>
		<td>{{ data.number_in_accounting }}</td>
		<td class="text-center" v-if="showEdit || showDelete"><span class="mr-2 cursor-pointer" v-on:click="onView"
																	v-if="showEdit === true"><i
			class="iconly-brokenShow icon-color"></i></span><span class="mr-2 cursor-pointer" v-on:click="onDelete"
																  v-if="showDelete === true"><i
			class="iconly-brokenDelete icon-color"></i></span></td>
	</tr>
</template>

<script>
import moment from "moment";

export default {
	name: 'OrderTableRow',
	props: {
		data: {
			type: Object,
			required: true
		},
		companyId: {
			required: false
		},
		showDelete: {
			default: false
		},
		mode: {
			default: 'user'
		},
		showEdit: {
			default: false
		}
	},
	methods: {
		getSubscription: function () {
			try {
				let subscription = ''
				if (this.data.subscription) {
					if ('app_type_name' in this.data.subscription) {
						subscription += `${this.data.subscription.app_type_name}` + ' - '
					}
					if ('company' in this.data.subscription) {
						subscription += this.data.subscription.company
					}
				}
				return subscription
			} catch (e) {
				return ''
			}

		},
		parseDateTime: function (dt) {
			if (dt) {
				return moment(dt).format('YYYY-MM-DD HH:MM a')
			} else {
				return ''
			}
		},
		onView: function () {
			window.Bus.$emit('open-am-queries', {data: this.data})
		},
		onDelete: function () {
			const URL = this.companyId ? this.$services.endpoints.GET_ORDERS + `${this.data.id}/?user_id=${this.companyId}` : this.$services.endpoints.GET_ORDERS + `${this.data.id}/`
			this.$confirm(`${this.$t('deleteConfirmation')}?`, '', {
				confirmButtonText: this.$t('yes'),
				cancelButtonText: this.$t('no'),
				type: 'warning',
				beforeClose: (action, instance, done) => {
					if (action === 'confirm') {
						instance.confirmButtonLoading = true;
						instance.confirmButtonText = 'Loading...';
						if (this.mode === 'user') {
							this.$store.dispatch('customer/customDeleteRequest', {URL: URL}).then(res => {
								instance.confirmButtonLoading = false;
								instance.confirmButtonText = this.$t('yes');
								this.$emit('removeOrder', this.data.id)
								done()
							}).catch(err => {
								this.$services.helpers.notification(this.$t('orderDeleteError'), 'error', this)
							})
						} else {
							this.$store.dispatch('house/customDeleteRequest', {URL: URL}).then(res => {
								instance.confirmButtonLoading = false;
								instance.confirmButtonText = this.$t('yes');
								this.$emit('removeOrder', this.data.id)
								done()
							}).catch(err => {
								this.$services.helpers.notification(this.$t('orderDeleteError'), 'error', this)
							})
						}

					} else {
						done();
					}
				}
			}).then(res => {
			}).catch(() => {

			});
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
