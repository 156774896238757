<template>
	<tr>
		<td v-for="(item,k) in displayField" :key="k">
			<span v-if="dateTimeField.includes(item)">
					{{ parseDateTime(dataObj[item]) }}
			</span>
			<span v-else-if="item === 'subscription'">
				{{ getSubscription() }}
				<!--				<span v-if="dataObj[item] && subscriptionMode === 'partial'">{{-->
				<!--						dataObj[item].account_identifier-->
				<!--					}}</span>-->
				<!--				<span v-if="dataObj[item] && subscriptionMode === 'full'">{{ dataObj[item].name }}</span>-->
			</span>
			<span v-else>
				{{ dataObj[item] }}
			</span>
		</td>
		<td class="text-center flex">
			<span class="mr-2 cursor-pointer" v-on:click="onView" v-if="showView === true"><i
				class="iconly-brokenShow icon-color"></i></span>
			<span class="mr-2 cursor-pointer" v-on:click="onEdit" v-if="showEdit === true"><i
				class="iconly-brokenEdit icon-color"></i></span><span class="mr-2 cursor-pointer" v-on:click="onDelete"
																	  v-if="showDelete === true"><i
			class=" iconly-brokenDelete icon-color"></i></span></td>
	</tr>
</template>

<script>
import moment from "moment";

export default {
	name: "invoicePaymentRow",
	props: {
		dataObj: {
			type: Object,
			required: true
		},
		displayField: {
			type: Array
		},
		companyId: {
			type: Number,
			required: false
		},
		showDelete: {
			default: false
		},
		mode: {
			default: 'user'
		},
		showView: {
			default: false
		},
		showEdit: {
			default: false
		},
		endpoint: {
			default: '',
			required: true
		},
		dateTimeField: {
			type: Array,
			required: false,
			default: []
		},
		editOption: {
			type: Object,
			default: {
				mode: 'default'
			}
		},
		subscriptionMode: {
			default: "partial"
		}
	},
	methods: {
		parseDateTime: function (dt) {
			if (dt) {
				return moment(dt).format('YYYY-MM-DD HH:MM a')
			} else {
				return ''
			}
		},
		onView: function () {
			if (this.editOption.hasOwnProperty('view_name')) {
				window.Bus.$emit(this.editOption.view_name, {data: this.dataObj})
			} else {
				window.Bus.$emit('open-am-queries', {data: this.dataObj})
			}

		},
		onEdit: function () {
			if (this.editOption) {
				window.Bus.$emit(this.editOption.edit_name, {data: this.dataObj})
			}
		},
		getSubscription: function () {
			try {
				let subscription = ''
				if (this.dataObj.subscription) {
					if ('app_type' in this.dataObj.subscription) {
						subscription += `(${this.dataObj.subscription.app_type})` + ' - '
					}
					if ('app_type_name' in this.dataObj.subscription) {
						subscription += `${this.dataObj.subscription.app_type_name}` + ' - '
					}
					if ('account_identifier' in this.dataObj.subscription) {
						subscription += `${this.dataObj.subscription.account_identifier}` + ' - '
					}
					if ('company' in this.dataObj.subscription) {
						subscription += this.dataObj.subscription.company
					}
				}
				return subscription
			} catch (e) {
				return ''
			}

		},
		onDelete: function () {
			const URL = this.companyId ? this.endpoint + `${this.customer.id}/?user_id=${this.companyId}` : this.endpoint + `${this.dataObj.id}/`
			this.$confirm(`${this.$t('deleteConfirmation')}?`, '', {
				confirmButtonText: this.$t('yes'),
				cancelButtonText: this.$t('no'),
				type: 'warning',
				beforeClose: (action, instance, done) => {
					if (action === 'confirm') {
						instance.confirmButtonLoading = true;
						instance.confirmButtonText = 'Loading...';
						if (this.mode === 'user') {
							this.$store.dispatch('customer/customDeleteRequest', {URL: URL}).then(res => {
								instance.confirmButtonLoading = false;
								instance.confirmButtonText = this.$t('yes');
								this.$emit('removeObj', this.dataObj.id)
								done()
							}).catch(err => {
								instance.confirmButtonLoading = false;
								instance.confirmButtonText = this.$t('yes');
								this.$services.helpers.notification(this.$t('entryDeleted'), 'error', this)
							})
						} else {
							this.$store.dispatch('house/customDeleteRequest', {URL: URL}).then(res => {
								instance.confirmButtonLoading = false;
								instance.confirmButtonText = this.$t('yes');
								this.$emit('removeObj', this.dataObj.id)
								done()
							}).catch(err => {
								instance.confirmButtonLoading = false;
								instance.confirmButtonText = this.$t('yes');
								this.$services.helpers.notification(this.$t('deleteEntryError'), 'error', this)
							})
						}

					} else {
						done();
					}
				}
			}).then(res => {
			}).catch(() => {

			});
		}
	}
}
</script>

<style scoped>

</style>
