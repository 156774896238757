<template>
	<div class="app-main__inner">
		<div class="main-card mb-3">
			<div class="card-body">

				<div class="row mb-3">
					<div class="grid grid-cols-5">
						<!--tabs-animated body-tabs-animated-->
						<span class="nav-item" v-on:click="onTab(1)">
							<a role="tab" class="tab-btn nav-link text-center" :class="{'active': tab ===1}" id="tab-0"
							   data-toggle="tab"
							   href="javascript:void(0)"
							   aria-selected="true">
								<span class="text-xs-custom w-full">{{ $t('orders') }}</span>
							</a>
						</span>
						<span class="nav-item" v-on:click="onTab(2)">
							<a role="tab" class="tab-btn nav-link  text-center" id="tab-1" data-toggle="tab"
							   :class="{'active': tab ===2 }"
							   href="javascript:void(0)"
							   aria-selected="false">
								<span class="text-xs-custom w-full">{{ $t('article') }}</span>
							</a>
						</span>
						<span class="nav-item " v-on:click="onTab(3)">
							<a role="tab" class="tab-btn nav-link  text-center" data-toggle="tab"
							   :class="{'active': tab === 3 }"
							   href="javascript:void(0)"
							   aria-selected="false">
								<span class="text-xs-custom  w-full">{{ $t('customer') }}</span>
							</a>
						</span>
						<span class="nav-item " v-on:click="onTab(4)">
							<a role="tab" class="tab-btn nav-link  text-center" data-toggle="tab"
							   :class="{'active': tab === 4 }"
							   href="javascript:void(0)"
							   aria-selected="false">
								<span class="text-xs-custom w-full">{{ $t('invoice_payment') }}</span>
							</a>
						</span>
						<span class="nav-item " v-on:click="onTab(5)">
							<a role="tab" class="tab-btn nav-link  text-center" data-toggle="tab"
							   :class="{'active': tab === 5 }"
							   href="javascript:void(0)"
							   aria-selected="false">
								<span class="text-xs-custom w-full">{{ $t('vouchers') }}</span>
							</a>
						</span>

					</div>
				</div>

			</div>

			<div class="tab-content">
				<generic-order v-if="tab ===1"/>
				<generic-article v-if="tab === 2"/>
				<generic-customer v-if="tab === 3"/>
				<generic-invoice-payment v-if="tab === 4"/>
				<generic-vouchers v-if="tab === 5"/>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import 'vue-json-pretty/lib/styles.css';
import Paginate from 'vuejs-paginate'
import {DatePicker} from 'element-ui'
import genericOrder from "./genericResources/genericOrder";
import genericArticle from "./genericResources/genericArticle";
import genericCustomer from "./genericResources/genericCustomer";
import genericInvoicePayment from "./genericResources/genericInvoicePayment";
import genericVouchers from "./genericResources/genericVouchers";
Vue.use(DatePicker)

export default {
	name: 'genericViews',
	components: {
		paginate: Paginate,
		genericOrder,
		genericArticle,
		genericInvoicePayment,
		genericVouchers,
		genericCustomer
	},
	data() {
		return {
			tab: 1,
			orders: {results: []},
			products: {results: []},
			showProductBtn: true,
			showOrderBtn: true,
			sub: '',
			subscriptions: [],
			productSubscription: [],
			orderSubscription: [],
			showSalesPanel: false,

			AppLogs: [],
			copyAppLog: [],
			sytemAppLogs: [],
			copySystemLogs: [],
			value1: '',
			value2: '',
			app_type: [],
			parPage: 10,
			currentPage: 1,
			loading: false,
			currentSystemPage: 1,
			vloading: false
		}
	},
	methods: {
		onTab: function (value) {
			this.tab = parseInt(value)
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.form-select {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-print-color-adjust: exact;
	color-adjust: exact;
	background-repeat: no-repeat;
	background-color: #fff;
	border-color: #e2e8f0;
	border-width: 1px;
	border-radius: 0.25rem;
	padding-top: 0.3rem !important;
	padding-right: 2.5rem;
	padding-bottom: 0.5rem;
	padding-left: 0.75rem;
	font-size: 0.9rem !important;
	line-height: 1.5;
	background-position: right 0.5rem center;
	background-size: 1.5em 1.5em;
}

.sticky-table {
	position: -webkit-sticky;
	position: sticky;
	top: 61px;
	background-color: #fff;
	// background-color: #e8eaec;
	height: 50px;
	padding: 7px;
}

.card-body {
	padding: 0 0.9rem !important;
}

.app-main .app-main__inner {
	padding-top: 20px !important;
}

.body-tabs-shadow .body-tabs-animated {
	padding: unset !important;
}

.tab-btn.active {
	box-shadow: unset !important;
	border: 1px solid $theme-secondary-color !important;
	font-weight: 800;
	color: $theme-secondary-color !important;
	border-radius: 5px;
}

.tab-btn {
	color: $theme-secondary-light;
}

.remove-bg {
	background: unset !important;
	background-color: unset !important;
}


.text-xs-custom {
	font-size: 14px !important;
	line-height: 1rem !important;
}
</style>
