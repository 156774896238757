<template>
	<div class="app-main__inner">

		<div class="row mb-3">
			<div class="col-md-12">
				<div class="flex justify-content-end">
					<a href="javascript:void(0)" v-on:click="onOpenDrawer"
					   class="c-btn btn-primary btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900">
						<span class="text-xs-custom">{{ $t('uploadCustomAccount') }}</span>
					</a>

				</div>

			</div>
		</div>

		<div class="row mb-2 mt-3">
			<div class="col-md-12">
				<div class="main-card mb-3 card">
					<div class="card-body">
						<div class="row sticky-table mb-3 mt-3">
							<div class="col-lg-12 col-md-12">
								<div class="w-full">
									<div
										class="flex text-xs font-light justify-between align-items-center mr-1 -mt-4">
										{{ $t('apps') }}:
									</div>
									<div class="form-group mt-2">
										<el-select v-model="app_type" remote
												   reserve-keyword
												   :remote-method="remoteMethod" :placeholder="$t('search')"
												   filterable
												   v-on:change="onChange"
												   class="w-1/4"
												   size="mini">
											<el-option
												:key="`all`"
												:label="`${$t('all')}` "
												:value="`all`">
											</el-option>
											<el-option
												v-for="(item,key) in subscriptionList"
												:key="key"
												:label="`(${item.id})  ${item.app_type_name} - ${item.company_name}` "
												:value="item.id">
											</el-option>
										</el-select>
									</div>
								</div>
							</div>

						</div>

					</div>
				</div>
				<div class="w-full table-responsive bg-white table-container">
					<table style="width: 100%;" id="examplemmm" v-loading="vloading"
						   class="table table-hover table-striped table-bordered bg-white table-auto overflow-x-scroll w-full block">
						<thead>
						<tr>
							<th class="text-xs">{{ $t('subscription') }}</th>
							<th class="text-xs">{{ $t('media_file') }}</th>
							<th class="text-xs">{{ $t('delete_accounts') }}</th>
							<th class="text-xs">{{ $t('date') }}</th>
							<!--							<th class="text-xs"></th>-->
						</tr>
						</thead>
						<tbody v-if="accounts.results.length > 0" v-loading="vloading">
						<tr v-for="(account,key) in accounts.results" :key="key">
							<td>
								<span v-if="account.subscription">({{ account.subscription.id }}) {{ account.subscription.company_name }}</span></td>
							<td>{{ account.media_file }}</td>
							<td class="text-center"><span v-if="account.delete_accounts" class="badge bg-green-500 text-white w-2/4">{{ $t('yes') }}</span>
								<span v-else class="badge bg-red-500 text-white w-2/4">{{ $t('no') }}</span></td>
							<td>{{ parseDateTime(account.created_at) }}</td>
							<!--							<td>{{ account.created_at }}</td>-->
						</tr>
						</tbody>
						<tbody v-else>
						<tr>
							<td colspan="6" class="text-center">
											<span class="text-center"
												  style="text-align: center">{{ $t('no_date_available') }}</span>
							</td>
						</tr>
						</tbody>
					</table>

				</div>
				<div class="float-right mb-5 mt-3" v-if="accounts.results.length > 0">
					<paginate
						:page-count="total_pages"
						:click-handler="changePageNumber"
						:prev-text="$t('prev')"
						:next-text="$t('next')"
						:container-class="'pagination'">
					</paginate>
				</div>
			</div>
		</div>

		<!--		<div v-if="vloading === false" class="flex justify-center w-full mt-20">-->
		<!--			<svg class="animate-spin -ml-1 mr-3 h-7 w-7" xmlns="http://www.w3.org/2000/svg" fill="none"-->
		<!--				 viewBox="0 0 24 24">-->
		<!--				<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>-->
		<!--				<path class="opacity-75" fill="currentColor"-->
		<!--					  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>-->
		<!--			</svg>-->
		<!--		</div>-->
	</div>
</template>

<script>

import moment from 'moment'
import Paginate from "vuejs-paginate";

export default {
	name: 'customAccounts',
	components: {paginate: Paginate,},
	data: function () {
		return {
			subscriptionList: [],
			app_type: 'all',
			total_pages: 1,
			accounts: {
				'count': 0,
				'next': null,
				'previous': null,
				'results': [],
			},
			vloading: false,
			limit: 50
		}
	},
	methods: {
		parseDateTime: function (dt) {
			if (dt) {
				return moment(dt).format('YYYY-MM-DD HH:MM a')
			} else {
				return ''
			}
		},
		onOpenDrawer: function () {
			window.Bus.$emit('open-custom-account-uploader')
		},
		changePageNumber: function (number) {
			let URL = this.$services.endpoints.GET_CUSTOMER_ACCOUNT_UPLOADER + `?limit=${this.limit}&page=${number}`
			if (this.app_type !== 'all') {
				URL += `&subscription_id=${this.app_type}`
			}
			this.$store.dispatch('house/customGetRequest', {URL})
				.then(res => {
					this.vloading = false
					this.accounts.results = res.data.data.results
					this.total_pages = res.data.data.pages

				}).catch((err) => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {

							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		onChange: function () {
			this.vloading = true
			if (this.app_type === 'all') {
				return this.getAccountUploader()
			}
			const URL = this.$services.endpoints.GET_CUSTOMER_ACCOUNT_UPLOADER + `?limit=${this.limit}&subscription_id=${this.app_type}`
			this.$store.dispatch('house/customGetRequest', {URL})
				.then(res => {
					this.vloading = false
					this.accounts.results = res.data.data.results
					this.total_pages = res.data.data.pages

				}).catch((err) => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {

							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getAccountUploader() {
			this.vloading = true
			const URL = this.$services.endpoints.GET_CUSTOMER_ACCOUNT_UPLOADER + `?limit=${this.limit}`
			this.$store.dispatch('house/customGetRequest', {URL})
				.then(res => {
					this.vloading = false
					this.accounts.results = res.data.data.results
					this.total_pages = res.data.data.pages

				}).catch((err) => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {

							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getSubscriptionList() {
			const URL = this.$services.endpoints.GET_INHOUSE_SUBSCRIPTION + `?limit=50`
			this.$store.dispatch('house/getSubscriptionList', {URL: URL})
				.then(resp => {
					// let subscriptionList = this.GET_USER_SUBSCRIPTION.all_subscriptions.filter(item => ALLOWED_V3_APP_FOR_SYNC.includes(parseInt(item.app_type)))
					try {
						this.subscriptionList = resp.data.data.results
					} catch (e) {
					}
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getSubscriptionList()
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		remoteMethod(query) {
			if (query !== '') {
				this.loadingSub = false
				const URL = this.$services.endpoints.GET_INHOUSE_SUBSCRIPTION + `?search=${query}&limit=50`
				this.$store.dispatch('house/getSubscriptionList', {URL: URL})
					.then(resp => {
						this.subscriptionList = resp.data.data.results

					}).catch((err) => {
					this.loadingSub = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {

								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		}
	},
	mounted() {
		this.getAccountUploader()
		this.getSubscriptionList()
		window.Bus.$on('reload-reload-custom-account', e => {
			this.getAccountUploader()
		})
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.form-select {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-print-color-adjust: exact;
	color-adjust: exact;
	background-repeat: no-repeat;
	background-color: #fff;
	border-color: #e2e8f0;
	border-width: 1px;
	border-radius: 0.25rem;
	padding-top: 0.3rem !important;
	padding-right: 2.5rem;
	padding-bottom: 0.5rem;
	padding-left: 0.75rem;
	font-size: 0.9rem !important;
	line-height: 1.5;
	background-position: right 0.5rem center;
	background-size: 1.5em 1.5em;
}

.sticky-table {
	position: -webkit-sticky;
	position: sticky;
	top: 61px;
	background-color: #fff;
	// background-color: #e8eaec;
	height: 50px;
	padding: 7px;
}

.card-body {
	padding: 0 0.9rem !important;
}

.app-main .app-main__inner {
	padding-top: 20px !important;
}

.body-tabs-shadow .body-tabs-animated {
	padding: unset !important;
}

.tab-btn.active {
	box-shadow: unset !important;
	border: 1px solid $theme-secondary-color !important;
	font-weight: 800;
	color: $theme-secondary-color !important;
	border-radius: 5px;
}

.tab-btn {
	color: $theme-secondary-light;
}

.remove-bg {
	background: unset !important;
	background-color: unset !important;
}


.text-xs-custom {
	font-size: 14px !important;
	line-height: 1rem !important;
}

.btn-outline-pink {
	background: #fff;
	color: #ee4d81 !important;
	border: 1px solid #ee4d81 !important;
}

.back-btn {
	padding-top: 8px;
	color: #1045ca !important;
}
</style>
