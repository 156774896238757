<template>
	<tr>
		<td class="text-xs font-light w-24">{{ data.number_in_app }}</td>
		<td class="text-xs font-light w-52">
			{{ data.name }}
		</td>
		<td class="text-xs font-light w-52" v-if="data.subscription">
			{{ getSubscription() }}
		</td>
		<td class="text-xs font-light w-24">{{ data.sku }}</td>
		<td class="text-xs font-light w-16">{{ data.stock_quantity }}</td>
		<td class="text-xs w-24">
			<span>{{ data.price }}</span>
		</td>
		<td class="text-xs font-light w-52">{{ data.subscription.account_identifier }}</td>
		<td class="text-xs font-light w-24">
			<span v-if="data">
				<span v-if="data.number_in_accounting" class="badge badge-success px-2 w-3/4 rounded">
					{{ $t('synced') }}
				</span>
				<span v-else class="badge badge-danger w-3/4 px-2 rounded">
					{{ $t('notSync') }}
				</span>

			</span>
		</td>
		<td class="text-xs font-light w-36">{{ parseDateTime(data.date_updated) }}</td>
		<td class="text-center w-24"><span class="mr-2 cursor-pointer" v-on:click="onView" v-if="showEdit === true"><i
			class="iconly-brokenShow icon-color"></i></span><span class="mr-2 cursor-pointer" v-on:click="onDelete"><i
			class="iconly-brokenDelete icon-color"></i></span></td>
	</tr>
</template>

<script>

import moment from "moment";

export default {
	name: 'ProductTableRow',
	props: {
		data: {
			type: Object,
			required: true
		},
		companyId: {
			required: false
		},
		mode: {
			default: 'user'
		},
		showEdit: {
			default: false
		}
	},
	methods: {
		parseDateTime: function (dt) {
			if (dt) {
				return moment(dt).format('YYYY-MM-DD HH:MM a')
			} else {
				return ''
			}
		},
		getSubscription: function () {
			try {
				let subscription = ''
				if (this.data.subscription) {
					if ('app_type_name' in this.data.subscription) {
						subscription += `${this.data.subscription.app_type_name}` + ' - '
					}
					if ('company' in this.data.subscription) {
						subscription += this.data.subscription.company
					}
				}
				return subscription
			} catch (e) {
				return ''
			}

		},
		onDelete: function () {
			const URL = this.companyId ? this.$services.endpoints.GET_PRODUCTS + `${this.data.id}/?user_id=${this.companyId}` : this.$services.endpoints.GET_PRODUCTS + `${this.data.id}/`
			this.$confirm(`${this.$t('deleteConfirmation')}?`, '', {
				confirmButtonText: this.$t('yes'),
				cancelButtonText: this.$t('no'),
				type: 'warning',
				beforeClose: (action, instance, done) => {
					if (action === 'confirm') {
						instance.confirmButtonLoading = true;
						instance.confirmButtonText = 'Loading...';
						if (this.mode === 'user') {
							this.$store.dispatch('customer/customDeleteRequest', {URL: URL}).then(res => {
								instance.confirmButtonLoading = false;
								instance.confirmButtonText = this.$t('yes');
								this.$emit('removeProduct', this.data.id)
								done()
							}).catch(err => {
								this.$services.helpers.notification(this.$t('productDeleteError'), 'error', this)
							})
						} else {
							this.$store.dispatch('house/customDeleteRequest', {URL: URL}).then(res => {
								instance.confirmButtonLoading = false;
								instance.confirmButtonText = this.$t('yes');
								this.$emit('removeProduct', this.data.id)
								done()
							}).catch(err => {
								this.$services.helpers.notification(this.$t('productDeleteError'), 'error', this)
							})
						}

					} else {
						done();
					}
				}
			}).then(res => {
			}).catch(() => {

			});
		},
		onView: function () {
			window.Bus.$emit('open-am-queries', {data: this.data})
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
