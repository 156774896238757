<template>
	<div class="tab-pane tabs-animation fade active show" id="tab-content-0" role="tabpanel">
		<div class="contaner">
			<div class="main-card mb-3 card">
				<div class="card-body">
					<div class="row sticky-table mb-3 mt-3">
						<div class="col-md-4">
							<div class="flex w-full">
								<div class="form-group w-full">
									<label>{{ $t('subscription') }}</label>
									<el-select v-model="sub" :placeholder="$t('search')" filterable
											   @change="changeAppName"
											   class="w-full"
											   remote
											   reserve-keyword
											   :remote-method="remoteMethod"
											   size="mini">
										<el-option
											:key="`all`"
											:label="`${$t('all')}` "
											:value="`all`">
										</el-option>
										<el-option
											v-for="(item,key) in subscriptionList"
											:key="key"
											:label="`(${item.id})  ${item.app_type_name} - ${item.name}` "
											:value="item.id">
										</el-option>
									</el-select>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="flex w-full">
								<div class="form-group w-full">
									<label>{{ $t('status') }}</label>
									<el-select v-model="filterParams.status" :placeholder="$t('search')" clearable
											   @change="changeAppName"
											   class="w-full"
											   size="mini">
										<el-option
											:key="`all`"
											:label="`${$t('all')}` "
											:value="`all`">
										</el-option>
										<el-option
											v-for="(item,key) in statusList"
											:key="key"
											:label="$t(`${item.key}`) "
											:value="item.filter">
										</el-option>

									</el-select>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="flex w-full">
								<div class="form-group w-full">
									<label>{{ $t('app') }}</label>
									<el-select v-model="filterParams.app_id" :placeholder="$t('search')" clearable
											   @change="changeAppName"
											   class="w-full"
											   size="mini">
										<el-option
											v-for="(item,key) in appList"
											:key="key"
											:label="item.key"
											:value="item.value">
										</el-option>

									</el-select>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="flex w-full">
								<div class="form-group w-full">
									<label>{{ $t('customerType') }}</label>
									<el-select v-model="filterParams.customer_type" :placeholder="$t('search')"
											   clearable
											   @change="changeAppName"
											   class="w-full"
											   size="mini">
										<el-option
											v-for="(item,key) in customerTypeList"
											:key="key"
											:label="$t(`${item.key}`)"
											:value="item.filter">
										</el-option>

									</el-select>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="flex w-full">
								<div class="form-group w-full">
									<label>{{ $t('accounting') }}</label>
									<el-select v-model="filterParams.accounting" :placeholder="$t('search')" clearable
											   @change="changeAppName"
											   class="w-full"
											   size="mini">
										<el-option
											v-for="(item,key) in accountingList"
											:key="key"
											:label="$t(`${item.key}`) "
											:value="item.filter">
										</el-option>

									</el-select>
								</div>
							</div>
						</div>
						<div class="col-md-4 ">
							<div class="flex justify-start lg:justify-end">
								<div class="form-group w-full">
									<label>{{ $t('search') }}</label>
									<input @input="searchInputFilter" type="text" v-model="filterParams.search"
										   :placeholder="$t('search')"
										   class="form-input custom-input w-full text-xs">
								</div>
							</div>
						</div>

					</div>

				</div>
			</div>
			<div>
				<div v-if="vloading" class="flex justify-center w-full">
					<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-400"
						 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
						<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
								stroke-width="4"></circle>
						<path class="opacity-75" fill="currentColor"
							  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
					</svg>
				</div>
				<div class="w-full table-responsive bg-white table-container">
					<table style="width: 100%;" id="examplemmm"
						   class="table table-hover table-striped table-bordered bg-white table-auto overflow-x-scroll w-full block">
						<thead>
						<tr>
							<th class="text-xs">
							<span class="flex justify-between">
								<span>{{ $t('name') }}</span>
								<span class="relative mr-1">
													<i v-on:click.prevent="sortCustomer('name')"
													   class="fa fa-chevron-up absolute bottom-2 cursor-pointer"></i>
													<i v-on:click.prevent="sortCustomer('-name')"
													   class="fa fa-chevron-down absolute top-2 cursor-pointer"></i>
												</span>
							</span>
							</th>
							<th class="text-xs" style="min-width: 290px !important;">
							<span class="flex justify-between">
								<span>{{ $t('subscription') }}</span>
								<!--								<span class="relative mr-1">-->
								<!--													<i v-on:click.prevent="sortCustomer('name')"-->
								<!--													   class="fa fa-chevron-up absolute bottom-2 cursor-pointer"></i>-->
								<!--													<i v-on:click.prevent="sortCustomer('-name')"-->
								<!--													   class="fa fa-chevron-down absolute top-2 cursor-pointer"></i>-->
								<!--												</span>-->
							</span>
							</th>
							<th class="text-xs">
							<span class="flex justify-between">
								<span>{{ $t('email') }}</span>
								<span class="relative mr-1">
													<i v-on:click.prevent="sortCustomer('email')"
													   class="fa fa-chevron-up absolute bottom-2 cursor-pointer"></i>
													<i v-on:click.prevent="sortCustomer('-email')"
													   class="fa fa-chevron-down absolute top-2 cursor-pointer"></i>
												</span>
							</span>
							</th>
							<th class="text-xs">
							<span class="flex justify-between">
								<span>{{ $t('phone') }}</span>
								<span class="relative mr-1">
													<i v-on:click.prevent="sortCustomer('phone')"
													   class="fa fa-chevron-up absolute bottom-2 cursor-pointer"></i>
													<i v-on:click.prevent="sortCustomer('-phone')"
													   class="fa fa-chevron-down absolute top-2 cursor-pointer"></i>
												</span>
							</span></th>
							<th class="text-xs">
							<span class="flex justify-between">
								<span>{{ $t('billing_email') }}</span>
								<span class="relative mr-1">
													<i v-on:click.prevent="sortCustomer('billing_email')"
													   class="fa fa-chevron-up absolute bottom-2 cursor-pointer"></i>
													<i v-on:click.prevent="sortCustomer('-billing_email')"
													   class="fa fa-chevron-down absolute top-2 cursor-pointer"></i>
												</span>
							</span></th>
							<th class="text-xs">
							<span class="flex justify-between">
								<span>{{ $t('zipcode') }}</span>
								<span class="relative mr-1">
													<i v-on:click.prevent="sortCustomer('zipcode')"
													   class="fa fa-chevron-up absolute bottom-2 cursor-pointer"></i>
													<i v-on:click.prevent="sortCustomer('-zipcode')"
													   class="fa fa-chevron-down absolute top-2 cursor-pointer"></i>
												</span>
							</span></th>
							<th class="text-xs">
							<span class="flex justify-between">
								<span>{{ $t('country') }}</span>
								<span class="relative mr-1">
													<i v-on:click.prevent="sortCustomer('country')"
													   class="fa fa-chevron-up absolute bottom-2 cursor-pointer"></i>
													<i v-on:click.prevent="sortCustomer('-country')"
													   class="fa fa-chevron-down absolute top-2 cursor-pointer"></i>
												</span>
							</span></th>
							<th class="text-xs"></th>
						</tr>
						</thead>
						<tbody>
						<customer-row @removeCustomer="removeCustomer" :show-delete="true"
									  :customer="customer" :mode="`admin`"
									  v-for="(customer, i) in customers.results" :key="i"/>
						</tbody>
					</table>
				</div>
				<div class="mt-3 mb-5" v-if="customers.results.length > 0">
					<div class="flex justify-between">
						<div>
							<select class="text-xs form-select w-full removs-bg" v-on:change="onChangeLimit"
									v-model="limit"
							>
								<option value="20">20</option>
								<option value="50">50</option>
								<option value="100">100</option>
								<option value="150">150</option>
								<option value="200">200</option>

							</select>
						</div>

						<paginate
							:page-count="customers.total_page"
							:click-handler="changePageNumber"
							:prev-text="$t('prev')"
							:next-text="$t('next')"
							:container-class="'pagination'">
						</paginate>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import customerRow from "./customerRow";
import Paginate from "vuejs-paginate";
import {APPID} from "../../../app/index";
import debounce from "lodash.debounce"

export default {
	name: "genericCustomer",
	components: {
		paginate: Paginate,
		customerRow
	},
	data: function () {
		return {
			value1: '',
			vloading: false,
			subscriptionList: [],
			sub: '',
			customers: {
				results: [],
				total_page: 1
			},
			limit: 200,
			sortValue: '',
			filterParams: {
				search: '',
				app_id: '',
				status: null,
				customer_type: null,
				accounting: null,
			},
			statusList: [
				{key: 'synced', 'filter': 'status=False'},
				{key: 'notSync', 'filter': 'status=True'},
				{key: 'activeSubscription', 'filter': 'subscription__settings__status=1'},
				{key: 'inactiveSubscription', 'filter': 'subscription__settings__status=0'},
				{key: 'removeSubscription', 'filter': 'subscription__settings__status=2'},
				{key: 'pauseSubscription', 'filter': 'subscription__settings__status=3'},
				{key: 'cancelledSubscription', 'filter': 'subscription__settings__status=4'},
			],
			appList: [],
			customerTypeList: [
				{key: 'companyOwner', 'filter': 'customer_type=2'},
				{key: 'privateOwner', 'filter': 'customer_type=1'},
			],
			accountingList: [
				{key: 'Fortnox', filter: 'subscription__accounting__accounting_type=0'},
				{key: 'Visma', filter: 'subscription__accounting__accounting_type=1'},
				{key: 'Visma Admin', filter: 'subscription__accounting__accounting_type=2'},
			]
		}
	},
	methods: {
		searchInputFilter: debounce(function () {
			this.changeAppName()
		}, 1000),
		changePageNumber: function (number) {
			let URL = this.$services.endpoints.GET_CUSTOMERS + `?page_size=${this.limit}&page=${number}`
			if (this.sub !== 'all' && this.sub !== '') {
				URL += `&subscription_id=${this.sub}`
			}
			if (this.sortValue) {
				URL += `&ordering=${this.sortValue}`
			}
			if (this.computeFilter()) {
				URL += this.computeFilter()
			}
			this.$store.dispatch('house/customGetRequest', {URL: URL})
				.then(res => {
					this.customers = res.data.data
				}).catch(err => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		onChangeLimit: function () {
			window.localStorage.setItem('customer_limit', this.limit)
			let URL = this.$services.endpoints.GET_CUSTOMERS + `?page_size=${this.limit}&`
			if (this.sub !== 'all' && this.sub !== '') {
				URL += `&subscription_id=${this.sub}`
			}
			if (this.sortValue) {
				URL += `&ordering=${this.sortValue}`
			}
			if (this.computeFilter()) {
				URL += this.computeFilter()
			}
			this.$store.dispatch('house/customGetRequest', {URL: URL})
				.then(res => {
					this.customers = res.data.data
				}).catch(err => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		sortCustomer: function (sortValue) {
			this.sortValue = sortValue
			let URL = this.$services.endpoints.GET_CUSTOMERS + `?page_size=${this.limit}&ordering=${this.sortValue}`
			if (this.sub !== 'all' && this.sub !== '') {
				URL += `?subscription_id=${this.sub}`
			}
			console.log()
			this.$store.dispatch('house/customGetRequest', {URL: URL})
				.then(res => {
					this.customers = res.data.data
				}).catch(err => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		changeAppName: function () {
			let URL = this.$services.endpoints.GET_CUSTOMERS + `?limit=${this.limit}`
			if (this.sub !== 'all' && this.sub !== '') {
				URL += `&subscription_id=${this.sub}`
			}
			// if (this.filterParams.status) {
			// 	URL += `&${this.filterParams.status}`
			// }
			// if (this.filterParams.app_id) {
			// 	URL += `&${this.filterParams.app_id}`
			// }
			// if (this.filterParams.search) {
			// 	URL += `&search=${this.filterParams.search}`
			// }
			// if (this.filterParams.customer_type) {
			// 	URL += `&${this.filterParams.customer_type}`
			// }
			if (this.computeFilter()) {
				URL += this.computeFilter()
			}
			this.$store.dispatch('house/customGetRequest', {URL: URL})
				.then(res => {
					this.customers = res.data.data
				}).catch(err => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		computeFilter() {
			let URL = ''
			if (this.filterParams.status) {
				URL += `&${this.filterParams.status}`
			}
			if (this.filterParams.app_id) {
				URL += `&${this.filterParams.app_id}`
			}
			if (this.filterParams.search) {
				URL += `&search=${this.filterParams.search}`
			}
			if (this.filterParams.customer_type) {
				URL += `&${this.filterParams.customer_type}`
			}
			if (this.filterParams.accounting) {
				URL += `&${this.filterParams.accounting}`
			}
			return URL
		},
		removeCustomer: function (customer_id) {
			let customerIndex = this.customers.results.findIndex(item => item.id === customer_id)
			if (customerIndex > -1) {
				this.customers.results.splice(customerIndex, 1)
			}
		},
		getCustomers() {
			this.vloading = true
			const URL = this.$services.endpoints.GET_CUSTOMERS + `?page_size=${this.limit}`
			this.$store.dispatch('customer/customGetRequest', {URL})
				.then(res => {
					this.vloading = false
					this.customers = res.data.data
				}).catch((err) => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},

		onOpenDrawer: function () {
			window.Bus.$emit('open-app-sync-drawer')
		},
		remoteMethod(query) {
			if (query !== '') {
				const URL = this.$services.endpoints.GET_INHOUSE_SUBSCRIPTION + `?search=${query}&limit=500`
				this.$store.dispatch('house/getSubscriptionList', {URL: URL})
					.then(response => {
						this.subscriptionList = response.data.data.results

					}).catch((err) => {
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {

								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		},
		getSubscriptionList() {
			const URL = this.$services.endpoints.GET_INHOUSE_SUBSCRIPTION
			this.$store.dispatch('house/getSubscriptionList', {URL: URL})
				.then(response => {
					this.subscriptionList = response.data.data.results
					this.showSalesPanel = true
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {

							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		}
	},
	mounted() {
		this.limit = window.localStorage.getItem('customer_limit') !== null ? window.localStorage.getItem('customer_limit') : 20
		this.getSubscriptionList()
		this.getCustomers()
	},
	created() {
		this.appList = []
		for (const [key, value] of Object.entries(APPID)) {
			let db = {key: key.replace("_", ' '), value: `subscription__app_type=${value}`}
			this.appList.push(db)
		}
	}
}
</script>

<style scoped>

</style>
