<template>
	<tr>
		<!--		<td>{{ customer.customer_type }}</td>-->
		<td>{{ customer.name }}</td>
		<td><span v-if="customer.subscription"> {{ getSubscription() }}
		</span></td>
		<td>{{ customer.email }}</td>
		<td>{{ customer.phone }}</td>
		<td>{{ customer.billing_email }}</td>
		<td>{{ customer.zipcode }}</td>
		<td>{{ customer.country }}</td>
		<td class="text-center flex"><span class="mr-2 cursor-pointer" v-on:click="onView"><i
			class="iconly-brokenShow icon-color"></i></span><span class="mr-2 cursor-pointer" v-on:click="onDelete"
																  v-if="showDelete === true"><i
			class="iconly-brokenDelete icon-color"></i></span></td>
	</tr>
</template>

<script>
export default {
	name: "customerRow",
	props: {
		customer: {
			type: Object,
			required: true
		},
		companyId: {
			type: Number,
			required: false
		},
		showDelete: {
			default: false
		},
		mode: {
			default: 'user'
		},
		showEdit: {
			default: false
		}
	},
	methods: {
		onView: function () {
			window.Bus.$emit('open-am-queries', {data: this.customer})
		},
		onDelete: function () {
			const URL = this.companyId ? this.$services.endpoints.GET_CUSTOMERS + `${this.customer.id}/?user_id=${this.companyId}` : this.$services.endpoints.GET_CUSTOMERS + `${this.customer.id}/`
			this.$confirm(`${this.$t('deleteConfirmation')}?`, '', {
				confirmButtonText: this.$t('yes'),
				cancelButtonText: this.$t('no'),
				type: 'warning',
				beforeClose: (action, instance, done) => {
					if (action === 'confirm') {
						instance.confirmButtonLoading = true;
						instance.confirmButtonText = 'Loading...';
						if (this.mode === 'user') {
							this.$store.dispatch('customer/customDeleteRequest', {URL: URL}).then(res => {
								instance.confirmButtonLoading = false;
								instance.confirmButtonText = this.$t('yes');
								this.$emit('removeCustomer', this.customer.id)
								done()
							}).catch(err => {
								this.$services.helpers.notification(this.$t('entryDeleted'), 'error', this)
							})
						} else {
							this.$store.dispatch('house/customDeleteRequest', {URL: URL}).then(res => {
								instance.confirmButtonLoading = false;
								instance.confirmButtonText = this.$t('yes');
								this.$emit('removeCustomer', this.customer.id)
								done()
							}).catch(err => {
								this.$services.helpers.notification(this.$t('orderDeleteError'), 'error', this)
							})
						}

					} else {
						done();
					}
				}
			}).then(res => {
			}).catch(() => {

			});
		},
		getSubscription: function () {
			let subscription = ''
			if (this.customer.subscription) {
				if ('app_type' in this.customer.subscription) {
					subscription += `(${this.customer.subscription.app_type})` + ' - '
				}
				if ('app_type_name' in this.customer.subscription) {
					subscription += `${this.customer.subscription.app_type_name}` + ' - '
				}
				if ('company' in this.customer.subscription) {
					subscription += this.customer.subscription.company
				}
			}
			return subscription
		}
	}
}
</script>

<style scoped>

</style>
