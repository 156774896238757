<template>
	<div class="tab-pane tabs-animation fade active show" id="tab-content-0" role="tabpanel">
		<div class="contaner">
			<div class="main-card mb-3 card">
				<div class="card-body">
					<div class="row align-items-center sticky-table mb-3 mt-3">
						<div class="col-md-4 align-items-center -mt-4">
							<label>{{ $t('dateRange') }}</label>
							<span class="-mt-2">
                                                <el-date-picker
													class="w-full text-xs font-light"
													v-model="value1"
													type="daterange"
													size="mini"
													clearable
													@change='dateFilterOrder'
													value-format="yyyy-MM-dd"
													format="yyyy/MM/dd"
													start-placeholder="Start datum"
													end-placeholder="Slutet datum">
                                                </el-date-picker>
                                            </span>
						</div>
						<div class="col-md-4 align-items-center">
							<div class="flex w-full">
								<div class="form-group w-full">
									<label>{{ $t('subscriptions') }}</label>
									<el-select v-model="sub" :placeholder="$t('search')" filterable clearable
											   @change="changeAppName"
											   class="w-full"
											   size="mini">
										<el-option
											v-for="(item,key) in orderSubscription"
											:key="key"
											:label="`(${item.id})  ${item.app_type_name} - ${item.name}` "
											:value="item.id">
										</el-option>
									</el-select>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="flex w-full">
								<div class="form-group w-full">
									<label>{{ $t('app') }}</label>
									<el-select v-model="filterParams.app_id" :placeholder="$t('search')" clearable
											   @change="changeAppName"
											   class="w-full"
											   size="mini">
										<el-option
											v-for="(item,key) in appList"
											:key="key"
											:label="item.key"
											:value="item.value">
										</el-option>

									</el-select>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="flex w-full">
								<div class="form-group w-full">
									<label>{{ $t('status') }}</label>
									<el-select v-model="filterParams.status" :placeholder="$t('search')" clearable
											   @change="changeAppName"
											   class="w-full"
											   size="mini">
										<el-option
											v-for="(item,key) in statusList"
											:key="key"
											:label="$t(`${item.key}`) "
											:value="item.filter">
										</el-option>

									</el-select>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="flex w-full">
								<div class="form-group w-full">
									<label>{{ $t('accounting') }}</label>
									<el-select v-model="filterParams.accounting" :placeholder="$t('search')" clearable
											   @change="changeAppName"
											   class="w-full"
											   size="mini">
										<el-option
											v-for="(item,key) in accountingList"
											:key="key"
											:label="$t(`${item.key}`) "
											:value="item.filter">
										</el-option>

									</el-select>
								</div>
							</div>
						</div>
						<div class="col-md-4 ">
							<div class="flex justify-start lg:justify-end">
								<div class="form-group w-full">
									<label>{{ $t('search') }}</label>
									<input @input="searchInputFilterOrder($event, 'order')" type="text"
										   :placeholder="$t('search')"
										   class="form-input w-full text-xs">
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
			<div>
				<div v-if="vloading" class="flex justify-center w-full">
					<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-400"
						 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
						<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
								stroke-width="4"></circle>
						<path class="opacity-75" fill="currentColor"
							  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
					</svg>
				</div>
				<div class="w-full table-responsive bg-white table-container">
					<table style="width: 100%;" id="examplemmm"
						   class="table table-hover table-striped table-bordered bg-white table-auto overflow-x-scroll w-full block">
						<thead>
						<tr>
							<th class="text-xs" v-for="(item,i) in headerList" :key="i">
								<span class="flex justify-between">
								<span>{{ $t(`${item.label}`) }}</span>
								<span class="relative mr-1" v-if="item.has_sort">
													<i v-on:click="sortOrder(`${item.key}`)"
													   class="fa fa-chevron-up absolute bottom-2 cursor-pointer"></i>
													<i v-on:click="sortOrder(`-${item.key}`)"
													   class="fa fa-chevron-down absolute top-2 cursor-pointer"></i>
												</span>
							</span>
							</th>
							<!--							<th class="text-xs">{{ $t('customer') }}</th>-->
							<!--							<th class="text-xs">{{ $t('subscription') }}</th>-->
							<!--							<th class="text-xs">{{ $t('amount') }}</th>-->
							<!--							<th class="text-xs">{{ $t('currency') }}</th>-->
							<!--							<th class="text-xs">{{ $t('date') }}</th>-->
							<!--							<th class="text-xs">{{ $t('status') }}</th>-->
							<!--							<th class="text-xs">{{ $t('reason') }}</th>-->
							<!--							<th class="text-xs">{{ $t('accounting') }}</th>-->
							<th class="text-xs"></th>
						</tr>
						</thead>
						<tbody>
						<OrderTableRow @removeOrder="removeOrder" :show-delete="true" :show-edit="true"
									   :data="order" :mode="`admin`"
									   v-for="(order, i) in orders.results" :key="i"/>
						</tbody>
					</table>
				</div>
				<div class="mb-5 mt-3" v-if="orders.results.length > 0">
					<div class="flex justify-between">
						<div>
							<select class="text-xs form-select w-full removs-bg" v-on:change="onChangeOrder"
									v-model="parPage"
							>
								<option value="20">20</option>
								<option value="50">50</option>
								<option value="100">100</option>
								<option value="150">150</option>
								<option value="200">200</option>

							</select>
						</div>
						<div>
							<paginate
								:page-count="orders.total_page"
								:click-handler="changePageNumber"
								:prev-text="$t('prev')"
								:next-text="$t('next')"
								:container-class="'pagination'">
							</paginate>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import OrderTableRow from "../../Automatisera/components/OrderTableRow";
import Paginate from "vuejs-paginate";
import {mapGetters} from "vuex";
import debounce from 'lodash/debounce'
import 'vue-json-pretty/lib/styles.css';
import {APPID} from "../../../app/index";

export default {
	name: "genericOrder",
	components: {
		paginate: Paginate,
		OrderTableRow
	},
	data() {
		return {
			filterParams: {
				search: '',
				app_id: '',
				status: null,
				accounting: null,
			},
			orders: {results: []},
			products: {results: []},
			showProductBtn: true,
			showOrderBtn: true,
			sub: '',
			statusList: [
				{key: 'synced', 'filter': 'status=False'},
				{key: 'notSync', 'filter': 'status=True'},
				{key: 'activeSubscription', 'filter': 'subscription__settings__status=1'},
				{key: 'inactiveSubscription', 'filter': 'subscription__settings__status=0'},
				{key: 'removeSubscription', 'filter': 'subscription__settings__status=2'},
				{key: 'pauseSubscription', 'filter': 'subscription__settings__status=3'},
				{key: 'cancelledSubscription', 'filter': 'subscription__settings__status=4'},
			],
			headerList: [
				{label: 'orderNumber', key: 'number_in_app', has_sort: true},
				{label: 'customer', key: 'customer_id', has_sort: true},
				{label: 'subscription', key: 'subscription_id', has_sort: true},
				{label: 'amount', key: 'total_price', has_sort: true},
				{label: 'currency', key: 'currency', has_sort: false},
				{label: 'date', key: 'date_updated', has_sort: true},
				{label: 'status', key: 'status', has_sort: false},
				{label: 'reason', key: 'reason', has_sort: false},
				{label: 'accounting', key: 'accounting', has_sort: false},
			],
			subscriptions: [],
			productSubscription: [],
			orderSubscription: [],
			showSalesPanel: false,

			AppLogs: [],
			copyAppLog: [],
			sytemAppLogs: [],
			copySystemLogs: [],
			value1: '',
			value2: '',
			sortValue: '',
			app_type: [],
			parPage: 200,
			currentPage: 1,
			loading: false,
			currentSystemPage: 1,
			vloading: false,
			appList: [],
			accountingList: [
				{key: 'Fortnox', filter: 'subscription__accounting__accounting_type=0'},
				{key: 'Visma', filter: 'subscription__accounting__accounting_type=1'},
				{key: 'Visma Admin', filter: 'subscription__accounting__accounting_type=2'},
			]
		}
	},
	computed: {
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_SUBSCRIPTION: 'customer/GET_SUBSCRIPTION',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_SYSTEM_LOGS: 'customer/GET_SYSTEM_LOGS',
			GET_APP_TYPE: 'customer/GET_APP_TYPE',
			GET_APP_LOG: 'customer/GET_APP_LOG'
		})
	},
	mounted() {
		this.parPage = window.localStorage.getItem('order_limit') !== null ? window.localStorage.getItem('order_limit') : 200
		this.getSubscriptionList()
		this.getOrders()

	},
	methods: {
		removeOrder: function (order_id) {
			let orderIndex = this.orders.results.findIndex(item => item.id === order_id)
			if (orderIndex > -1) {
				this.orders.results.splice(orderIndex, 1)
			}
		},
		searchInputFilterOrder: debounce(function (event, mode) {
			if (event.target.value !== '') {
				if (mode === 'order') {
					this.filterInput(event.target.value, 'order')
				} else {
					this.filterInput(event.target.value, 'product')
				}
			} else {
				if (mode === 'order') {
					this.getOrders(this.GET_USER_COMPANY_ID)
				} else {
					this.getProduct(this.GET_USER_COMPANY_ID)
				}
			}
		}, 500),
		changePageNumber(pageNum) {
			this.vloading = true
			let url = this.$services.endpoints.GET_ORDERS + `?page_size=${this.parPage}&page=${Number(pageNum)}`
			if (this.computeFilter()) {
				url += this.computeFilter()
			}
			this.$store.dispatch('house/customGetRequest', {URL: url})
				.then(res => {
					this.vloading = false
					this.orders = res.data.data
				}).catch(err => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.changePageNumber(pageNum)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		changePageNumberProduct(pageNum) {
			let url = this.$services.endpoints.GET_PRODUCTS + `?page_size=20&page=${Number(pageNum)}`
			if (this.sub) {
				url += `&subscription_id=${this.sub}`
			}
			this.$store.dispatch('house/customGetRequest', {URL: url})
				.then(res => {
					this.vloading = false
					this.products = res.data.data
				}).catch(err => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		onChangeOrder: function () {
			window.localStorage.setItem('order_limit', this.parPage)
			this.vloading = true
			let url = this.$services.endpoints.GET_ORDERS + `?page_size=${this.parPage}`
			if (this.computeFilter()) {
				url += this.computeFilter()
			}
			this.$store.dispatch('house/customGetRequest', {URL: url})
				.then(res => {
					this.vloading = false
					this.orders = res.data.data
				}).catch(err => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.changePageNumber(pageNum)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})

		},
		dateFilterOrder(e) {
			this.vloading = true
			const payload = {
				URL: this.$services.endpoints.GET_ORDERS + `?date_from=${e[0]}&date_to=${e[1]}&limit=${this.parPage}`
			}
			this.$store.dispatch('house/customGetRequest', payload)
				.then(res => {
					this.vloading = false
					this.orders = res.data.data
				}).catch(err => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		sortOrder: function (sortValue) {
			this.sortValue = sortValue
			this.changeAppName()
		},
		filterInput(value, input) {
			this.vloading = true
			const mode = input === 'order' ? this.$services.endpoints.GET_ORDERS : this.$services.endpoints.GET_PRODUCTS
			const payload = {
				URL: this.GET_USER_COMPANY_ID ?
					(this.sub ? mode + `${this.GET_USER_COMPANY_ID}/?subscription_id=${this.sub}&search=${value}&page_size=${this.parPage}` : mode + `${this.GET_USER_COMPANY_ID}/?search=${value}&page_size=${this.parPage}`) :
					(this.sub ? mode + `?subscription_id=${this.sub}&search=${value}&page_size=${this.parPage}` : mode + `?search=${value}&page_size=${this.parPage}`)
			}
			this.$store.dispatch('customer/customGetRequest', payload)
				.then(res => {
					this.vloading = false
					if (input === 'order') {
						this.orders = res.data.data
					} else {
						this.products = res.data.data
					}
				}).catch(err => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.filterInput(value, input)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}

			})
		},
		changeAppName() {
			this.vloading = true
			let URL = this.$services.endpoints.GET_ORDERS + `?page_size=${this.parPage}`
			if (this.computeFilter()) {
				URL += this.computeFilter()
			}
			this.$store.dispatch('customer/customGetRequest', {URL: URL})
				.then(res => {
					this.vloading = false
					this.orders = res.data.data
				}).catch(err => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		dateFilter(e) {
			this.$store.commit('customer/FILTER_SYSTEM_DATE', e)
		},
		dateFilterAudit(e) {
			this.$store.commit('customer/FILTER_AUDIT_DATE', e)
		},
		getOrders() {
			this.vloading = true
			const URL = this.$services.endpoints.GET_ORDERS + `?page_size=${this.parPage}`
			this.$store.dispatch('customer/customGetRequest', {URL})
				.then(res => {
					this.vloading = false
					this.orders = res.data.data
				}).catch((err) => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},

		onOpenDrawer: function () {
			window.Bus.$emit('open-app-sync-drawer')
		},
		computeFilter() {
			let URL = ''
			if (this.sub) {
				URL += `&subscription_id=${this.sub}`
			}
			if (this.filterParams.status) {
				URL += `&${this.filterParams.status}`
			}
			if (this.filterParams.app_id) {
				URL += `&${this.filterParams.app_id}`
			}
			if (this.filterParams.search) {
				URL += `&search=${this.filterParams.search}`
			}
			if (this.filterParams.accounting) {
				URL += `&${this.filterParams.accounting}`
			}
			if (this.sortValue) {
				URL += `&ordering=${this.sortValue}`
			}
			return URL
		},
		getSubscriptionList() {
			const URL = this.$services.endpoints.GET_INHOUSE_SUBSCRIPTION
			this.$store.dispatch('house/getSubscriptionList', {URL: URL})
				.then(response => {
					this.orderSubscription = response.data.data.results
					this.showSalesPanel = true
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {

							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		}

	},
	created() {
		this.appList = []
		for (const [key, value] of Object.entries(APPID)) {
			let db = {key: key.replace("_", ' '), value: `subscription__app_type=${value}`}
			this.appList.push(db)
		}
	}
}
</script>

<style scoped>

</style>
