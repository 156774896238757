<template>
	<div class="app-main__inner">
		<div class="main-card mb-3 mt-3">


			<div class="row mb-3">
				<div class="col-md-12">
					<div class="flex justify-content-end">
						<a href="javascript:void(0)" v-on:click="onOpenDrawer"
						   class="c-btn btn-primary btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900">
							<span class="text-xs-custom">{{ $t('triggerSync') }}</span>
						</a>

					</div>

				</div>
			</div>

			<div class="row mb-2 mt-3">
				<div class="col-md-12">
					<div class="main-card mb-3 card">
						<div class="card-body" style="    margin-top: 20px;">
							<div class="row stickytable mb-3 mt-3">
								<div class="col-lg-3 col-md-3">
									<div>
										<div
											class="flex text-xs font-light justify-between align-items-center mr-1 -mt-4 mb-0.5">
											{{ $t('date') }}:
										</div>
										<el-date-picker
											class="w-full text-xs font-light app-input"
											v-model="dateRange"
											type="date"
											size="mini"
											value-format="yyyy-MM-dd"
											format="yyyy/MM/dd"
											start-placeholder="Start datum"
											end-placeholder="Slutet datum">
										</el-date-picker>
									</div>
								</div>
								<div class="col-lg-4">
									<div class="">
										<div
											class="flex text-xs font-light justify-between align-items-center mr-1 -mt-4 mb-0.5">
											{{ $t('apps') }}:
										</div>
										<div class="form-group w-full">
											<el-select v-model="app_type" :placeholder="$t('search')" filterable
													   class="w-full"
													   size="mini">
												<el-option
													:key="`all`"
													:label="`${$t('all')}` "
													:value="`all`">
												</el-option>
												<el-option
													v-for="(item,key) in appList"
													:key="key"
													:label="`${item.name}` "
													:value="item.key">
												</el-option>
											</el-select>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-3">
									<div class="">
										<div
											class="flex text-xs font-light justify-between align-items-center mr-1 -mt-4 mb-0.5">
											<!-- <i class="iconly-brokenFilter mr-1"></i> -->
											{{ $t('syncStatus') }}:
										</div>
										<div class="form-group w-full">
											<select class="text-xs form-select w-full removs-bg"
													v-model="statusType">
												<option value="all">{{ $t('all') }}</option>
												<option value="Success">{{ $t('success') }}</option>
												<!--													<option value="Pending">{{ $t('pending') }}</option>-->
												<option value="Failed">{{ $t('failed') }}</option>

											</select>
										</div>
									</div>
								</div>
								<div class="col-lg-2 col-md-3">
									<div class="w-full flex justify-content-end">
										<button v-on:click="generateReport"
												class=" bg-purple-600 px-4 py-2 rounded-md text-white">
											{{ $t('filter') }}
										</button>
									</div>

								</div>
								<div class="col-lg-12">
									<div class="row mb-4">
										<div class="col-md-12">
											<div class="w-full relative">
														<span class="absolute right-1 cursor-pointer"
															  v-on:click="toggleaddOn"><i
															class="fa fa-chevron-down"></i></span>
											</div>
											<div class="grid grid-cols-3 mb-3 gap-3" v-if="addOn">
												<div class="">
													<label class="mb-0.5">{{ $t('company') }}</label>
													<el-select v-model="company" :placeholder="$t('search')" filterable
															   class="w-full"
															   size="mini"
															   remote
															   :loading="loadingCompany"
															   :loading-text="$t('loading')"
															   :no-data-text="$t('noData')"
															   :no-match-text="$t('noData')"
															   v-on:change="generateReport"
															   reserve-keyword
															   :remote-method="remoteCompanyMethod">
														<el-option
															v-for="(item,key) in companyList"
															:key="key"
															:label="`(${item.id})  ${item.company.name}` "
															:value="item.id">
														</el-option>
													</el-select>
												</div>
												<div class="">
													<label class="w-full mb-0.5">{{ $t('search') }}</label>
													<input type="text" class="form-input h-7 w-full text-xs"
														   :placeholder="$t('search')"
														   v-on:input="searchInput"
														   v-model="searchName"/>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>

						</div>
					</div>

				</div>
				<div class="col-md-12">
					<div class="w-full table-responsive bg-white table-container">
						<table style="width: 100%;" id="examplemmm" v-loading="vloading"
							   class="table table-hover table-striped table-bordered bg-white table-auto overflow-x-scroll w-full block">
							<thead>
							<tr>
								<th class="text-xs">{{ $t('app_name') }}</th>
								<th class="text-xs">{{ $t('sync_type') }}</th>
								<th class="text-xs">{{ $t('company') }}</th>
								<th class="text-xs">{{ $t('username') }}</th>
								<th class="text-xs">{{ $t('date') }}</th>
								<th class="text-xs">{{ $t('syncStatus') }}</th>
								<th class="text-xs">{{ $t('message') }}</th>
								<th class="text-xs"></th>
							</tr>
							</thead>
							<tbody v-if="reports.results.length > 0" v-loading="vloading">
							<app-synchronize-row v-for="(item,key) in reports.results" :key="key" :item="item"/>
							</tbody>
							<tbody v-else>
							<tr>
								<td colspan="8" class="text-center">
											<span class="text-center"
												  style="text-align: center">{{ $t('no_date_available') }}</span>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
					<div class="float-right mb-5 mt-3" v-if="reports.results.length > 0">
						<paginate
							:page-count="total_page"
							:click-handler="changePageNumber"
							:prev-text="$t('prev')"
							:next-text="$t('next')"
							:container-class="'pagination'">
						</paginate>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import AppSynchronizeRow from "../Automatisera/components/AppSynchronizeRow";
import Paginate from "vuejs-paginate";
import debounce from 'lodash/debounce'
import {ALLOWED_V3_SYNC_REPORT_APPS, ELEMENT_TYPES, ENABLED_V3_SYNC_APP} from "../../app/index";
import {mapGetters} from "vuex";
import Vue from "vue";
import {DatePicker} from "element-ui";

Vue.use(DatePicker)
export default {
	name: "userSpecificTasks",
	components: {
		AppSynchronizeRow,
		paginate: Paginate,
	},
	data: function () {
		return {
			showSyncPanel: true,
			vloading: true,
			loadingCompany: false,
			addOn: true,
			loading: true,
			reports: {
				"count": 0,
				"next": null,
				"previous": null,
				"results": [],


			},
			total_page: 1,
			limit: 50,
			dateRange: '',
			value2: '',
			searchName: '',
			appList: ENABLED_V3_SYNC_APP,
			company: '',
			subscriptionList: [],
			companyList: [],
			app_types: [],
			elementTypes: ELEMENT_TYPES,
			statusType: 'all',
			app_type: 'all',
			generating: false,
		}
	},
	computed: {
		...mapGetters({
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_USER_SUBSCRIPTION: 'customer/GET_SUBSCRIPTION',
			USER_REFRESH: 'auth/USER_REFRESH'
		})
	},
	methods: {
		toggleaddOn: function () {
			this.addOn = !this.addOn
		},
		onChangeCompany: function () {

		},
		searchInput: debounce(function () {
			if (this.searchName) {
				this.generateReport()

			} else {
				this.getSyncReport()
			}
		}, 500),
		changePageNumber: function (num) {
			let offset = this.limit * num
			let URL = this.$services.endpoints.APP_SYNC_REPORTS + `?limit=${this.limit}&page=${num}&offset=${offset}`
			if (this.dateRange) {
				URL += `&created_at=${this.dateRange}`
			}
			if (this.app_type !== 'all') {
				URL += `&subscription__app_type=${this.app_type}`
			}
			if (this.statusType !== 'all') {
				URL += `&status=${this.statusType}`
			}
			if (this.company) {
				URL += `&subscription__user__id=${this.company}`
			}
			if (this.searchName) {
				URL += `&search=${this.searchName}`
			}
			this.filterTasks(URL)
		},
		onOpenDrawer: function () {
			window.Bus.$emit('open-app-sync-drawer')
		},
		getSyncReport() {
			this.vloading = true
			const URL = this.$services.endpoints.APP_SYNC_REPORTS + `?limit=${this.limit}`
			this.$store.dispatch('house/customGetRequest', {URL})
				.then(res => {
					this.vloading = false
					this.reports = res.data
					this.total_page = Math.ceil(res.data.count / this.limit)
					if (this.total_page > 1) {
						this.total_page -= 1
					}
				}).catch((err) => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {

							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		generateReport: function () {
			let URL = this.$services.endpoints.APP_SYNC_REPORTS + `?limit=${this.limit}`
			// URL += `?limit=50`
			if (this.dateRange) {
				URL += `&created_at=${this.dateRange}`
			}
			if (this.app_type !== 'all') {
				URL += `&subscription__app_type=${this.app_type}`
			}
			if (this.statusType !== 'all') {
				URL += `&status=${this.statusType}`
			}
			if (this.company) {
				URL += `&subscription__user__id=${this.company}`
			}
			if (this.searchName) {
				URL += `&search=${this.searchName}`
			}
			// this.loading = true
			this.filterTasks(URL)
		},
		getSubscriptionList() {
			const URL = this.$services.endpoints.GET_INHOUSE_SUBSCRIPTION + `?limit=50`
			this.$store.dispatch('house/getSubscriptionList', {URL: URL})
				.then(resp => {
					// let subscriptionList = this.GET_USER_SUBSCRIPTION.all_subscriptions.filter(item => ALLOWED_V3_APP_FOR_SYNC.includes(parseInt(item.app_type)))
					try {
						ENABLED_V3_SYNC_APP.map(item => {
							let exist = resp.data.data.results.filter(sub => parseInt(sub.app_type) === parseInt(item.key))
							if (exist.length > 0) {
								this.subscriptionList.push(item)
							}
						})
					} catch (e) {
					}
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getSubscriptionList()
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		filterTasks(URL) {
			this.vloading = true
			this.$store.dispatch('house/customGetRequest', {URL})
				.then(res => {
					this.vloading = false
					this.reports = res.data
					this.total_page = Math.ceil(res.data.count / this.limit)
					if (this.total_page > 1) {
						this.total_page -= 1
					}
				}).catch(err => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.generateReport()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		remoteCompanyMethod: debounce(function (query) {
			if (query !== '') {
				this.loadingCompany = true
				this.$store.dispatch('house/searchCompany', {value: query, num: false})
					.then(res => {
						this.companyList = res.data.results
						this.loadingCompany = false
					}).catch(err => {
					this.loadingCompany = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		}, 500),
		remoteMethod(query) {
			if (query !== '') {
				this.loadingSub = false
				const URL = this.$services.endpoints.GET_INHOUSE_SUBSCRIPTION + `?search=${query}&limit=50`
				this.$store.dispatch('house/getSubscriptionList', {URL: URL})
					.then(response => {
						try {
							ENABLED_V3_SYNC_APP.map(item => {
								let exist = response.data.results.filter(sub => parseInt(sub.app_type) === parseInt(item.key))
								if (exist.length > 0) {
									this.subscriptionList.push(item)
								}
							})
						} catch (e) {
						}
						e


					}).catch((err) => {
					this.loadingSub = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {

								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		}
	},

	mounted() {
		// Implementing two-way fetching
		this.getSyncReport()
		this.getSubscriptionList()
		window.Bus.$on('reload-sync-event', e => {
			this.getSyncReport()
		})
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.form-select {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-print-color-adjust: exact;
	color-adjust: exact;
	background-repeat: no-repeat;
	background-color: #fff;
	border-color: #e2e8f0;
	border-width: 1px;
	border-radius: 0.25rem;
	padding-top: 0.3rem !important;
	padding-right: 2.5rem;
	padding-bottom: 0.5rem;
	padding-left: 0.75rem;
	font-size: 0.9rem !important;
	line-height: 1.5;
	background-position: right 0.5rem center;
	background-size: 1.5em 1.5em;
}

.sticky-table {
	position: -webkit-sticky;
	position: sticky;
	top: 61px;
	background-color: #fff;
	// background-color: #e8eaec;
	height: 50px;
	padding: 7px;
}

.card-body {
	padding: 0 0.9rem !important;
}

.app-main .app-main__inner {
	padding-top: 20px !important;
}

.body-tabs-shadow .body-tabs-animated {
	padding: unset !important;
}

.tab-btn.active {
	box-shadow: unset !important;
	border: 1px solid $theme-secondary-color !important;
	font-weight: 800;
	color: $theme-secondary-color !important;
	border-radius: 5px;
}

.tab-btn {
	color: $theme-secondary-light;
}

.remove-bg {
	background: unset !important;
	background-color: unset !important;
}


.text-xs-custom {
	font-size: 14px !important;
	line-height: 1rem !important;
}

.btn-outline-pink {
	background: #fff;
	color: #ee4d81 !important;
	border: 1px solid #ee4d81 !important;
}

.back-btn {
	padding-top: 8px;
	color: #1045ca !important;
}
</style>
