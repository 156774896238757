<template>
	<div role="tabpanel">
		<div class="contaner">
			<div class="main-card mb-3 card">
				<div class="card-body">
					<div class="row sticky-table mb-3 mt-3">
						<div class="col-md-4">
							<div class="flex w-full">
								<div class="form-group w-full">
									<label>{{ $t('subscription') }}</label>
									<el-select v-model="sub" :placeholder="$t('search')" filterable clearable
											   @change="changeAppName"
											   class="w-full"
											   remote
											   reserve-keyword
											   :remote-method="remoteMethod"
											   size="mini">
										<el-option
											v-for="(item,key) in productSubscription"
											:key="key"
											:label="`(${item.id})  ${item.app_type_name} - ${item.name}` "
											:value="item.id">
										</el-option>
									</el-select>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="flex w-full">
								<div class="form-group w-full">
									<label>{{ $t('status') }}</label>
									<el-select v-model="filterParams.status" :placeholder="$t('search')" clearable
											   @change="changeAppName"
											   class="w-full"
											   size="mini">
										<el-option
											v-for="(item,key) in statusList"
											:key="key"
											:label="$t(`${item.key}`) "
											:value="item.filter">
										</el-option>

									</el-select>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="flex w-full">
								<div class="form-group w-full">
									<label>{{ $t('accounting') }}</label>
									<el-select v-model="filterParams.accounting" :placeholder="$t('search')" clearable
											   @change="changeAppName"
											   class="w-full"
											   size="mini">
										<el-option
											v-for="(item,key) in accountingList"
											:key="key"
											:label="$t(`${item.key}`) "
											:value="item.filter">
										</el-option>

									</el-select>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="flex w-full">
								<div class="form-group w-full">
									<label>{{ $t('app') }}</label>
									<el-select v-model="filterParams.app_id" :placeholder="$t('search')" clearable
											   @change="changeAppName"
											   class="w-full"
											   size="mini">
										<el-option
											v-for="(item,key) in appList"
											:key="key"
											:label="item.key"
											:value="item.value">
										</el-option>

									</el-select>
								</div>
							</div>
						</div>
						<div class="col-md-4 ">
							<div class="flex justify-start lg:justify-end">
								<div class="form-group w-full">
									<label>{{ $t('search') }}</label>
									<input @input="searchInputFilter" type="text" v-model="filterParams.search"
										   :placeholder="$t('search')"
										   class="form-input custom-input w-full text-xs">
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
			<div class="w-full">
				<div v-if="vloading" class="flex justify-center w-full">
					<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-400"
						 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
						<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
								stroke-width="4"></circle>
						<path class="opacity-75" fill="currentColor"
							  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
					</svg>
				</div>
				<div class="w-full table-responsive bg-white table-container">
					<table style="width: 100%;" id="examplemmm"
						   class="table table-hover table-striped table-bordered bg-white table-auto overflow-x-scroll w-full block">
						<thead>
						<tr>
							<th class="text-xs" v-for="(item,i) in headerList" :key="i">
								<span class="flex justify-between">
								<span>{{ $t(`${item.label}`) }}</span>
								<span class="relative mr-1" v-if="item.has_sort">
													<i v-on:click="sortProduct(`${item.key}`)"
													   class="fa fa-chevron-up absolute bottom-2 cursor-pointer"></i>
													<i v-on:click="sortProduct(`-${item.key}`)"
													   class="fa fa-chevron-down absolute top-2 cursor-pointer"></i>
												</span>
							</span>
							</th>
							<!--							<th class="text-xs">{{ $t('articleNumber') }}</th>-->
							<!--							<th class="text-xs">{{ $t('articleName') }}</th>-->
							<!--							<th class="text-xs">{{ $t('subscription') }}</th>-->
							<!--							<th class="text-xs">SKU</th>-->
							<!--							<th class="text-xs">{{ $t('quantity') }}</th>-->
							<!--							<th class="text-xs">{{ $t('price') }}</th>-->
							<!--							<th class="text-xs">{{ $t('shop') }}</th>-->
							<!--							<th class="text-xs">{{ $t('status') }}</th>-->
							<th class="text-xs">

							</th>
						</tr>
						</thead>
						<tbody>
						<ProductTableRow
							@removeProduct="removeProduct" :data="product" :show-edit="true"
							v-for="(product, i) in products.results" :mode="`admin`"
							:key="i"/>
						</tbody>
					</table>
				</div>
				<div class="float-right mb-5 mt-3" v-if="products.results.length > 0">
					<paginate
						:page-count="products.total_page"
						:click-handler="changePageNumberProduct"
						:prev-text="$t('prev')"
						:next-text="$t('next')"
						:container-class="'pagination'">
					</paginate>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ProductTableRow from "../../Automatisera/components/ProductTableRow";
import Paginate from "vuejs-paginate";
import {APPID} from "./../../../app/index"
import debounce from 'lodash/debounce'

export default {
	name: "genericArticle",
	components: {
		paginate: Paginate,
		ProductTableRow
	},
	data() {
		return {
			products: {results: []},
			subscriptions: [],
			productSubscription: [],
			orderSubscription: [],
			showSalesPanel: false,
			appList: [],
			AppLogs: [],
			copyAppLog: [],
			sytemAppLogs: [],
			copySystemLogs: [],
			value1: '',
			value2: '',
			sortValue: '',
			sub: '',
			app_type: [],
			parPage: 10,
			currentPage: 1,
			loading: false,
			currentSystemPage: 1,
			headerList: [
				{label: 'articleNumber', key: 'number_in_app', has_sort: true},
				{label: 'articleName', key: 'name', has_sort: true},
				{label: 'subscription', key: 'subscription_id', has_sort: true},
				{label: 'SKU', key: 'sku', has_sort: true},
				{label: 'quantity', key: 'stock_quantity', has_sort: true},
				{label: 'price', key: 'price', has_sort: true},
				{label: 'shop', key: 'shop', has_sort: false},
				{label: 'status', key: 'status', has_sort: false},
				{label: 'date_updated', key: 'date_updated', has_sort: true},
			],
			filterParams: {
				status: null,
				accounting: null,
				search: '',
				app_id: ''
			},
			vloading: false,
			statusList: [
				{key: 'synced', 'filter': 'status=False'},
				{key: 'notSync', 'filter': 'status=True'},
				{key: 'activeSubscription', 'filter': 'subscription__settings__status=1'},
				{key: 'inactiveSubscription', 'filter': 'subscription__settings__status=0'},
				{key: 'removeSubscription', 'filter': 'subscription__settings__status=2'},
				{key: 'pauseSubscription', 'filter': 'subscription__settings__status=3'},
				{key: 'cancelledSubscription', 'filter': 'subscription__settings__status=4'},
			],
			accountingList: [
				{key: 'Fortnox', filter: 'subscription__accounting__accounting_type=0'},
				{key: 'Visma', filter: 'subscription__accounting__accounting_type=1'},
				{key: 'Visma Admin', filter: 'subscription__accounting__accounting_type=2'},
			]
		}
	},
	methods: {
		changeAppName() {
			this.vloading = true
			let URL = this.$services.endpoints.GET_PRODUCTS + `?limit=${this.parPage}`
			if (this.computeFilter()) {
				URL += this.computeFilter()
			}

			// if (this.filterParams.status !== 'all' && this.filterParams.status) {
			// 	URL += `&${this.filterParams.status}`
			// }
			// if (this.filterParams.app_id !== 'all' && this.filterParams.app_id) {
			// 	URL += `&${this.filterParams.app_id}`
			// }
			// if (this.filterParams.search) {
			// 	URL += `&search=${this.filterParams.search}`
			// }
			this.$store.dispatch('house/customGetRequest', {URL})
				.then(res => {
					this.vloading = false
					this.products = res.data.data
				}).catch(err => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		remoteMethod(query) {
			if (query !== '') {
				const URL = this.$services.endpoints.GET_INHOUSE_SUBSCRIPTION + `?search=${query}&limit=500`
				this.$store.dispatch('house/getSubscriptionList', {URL: URL})
					.then(response => {
						this.subscriptionList = response.data.data.results

					}).catch((err) => {
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {

								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		},
		getProduct() {
			this.vloading = true
			const URL = this.$services.endpoints.GET_PRODUCTS + '?page_size=20'
			this.$store.dispatch('house/customGetRequest', {URL})
				.then(res => {
					this.vloading = false
					this.products = res.data.data
				}).catch((err) => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getSubscriptionList() {
			const URL = this.$services.endpoints.GET_INHOUSE_SUBSCRIPTION + `?limit=50`
			this.$store.dispatch('house/getSubscriptionList', {URL: URL})
				.then(response => {
					this.productSubscription = response.data.data.results
					this.showSalesPanel = true
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {

							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		sortProduct(sortValue) {
			this.sortValue = sortValue
			this.changeAppName()
		},
		removeProduct: function (order_id) {
			let orderIndex = this.products.results.findIndex(item => item.id === order_id)
			if (orderIndex > -1) {
				this.products.results.splice(orderIndex, 1)
			}
		},
		changePageNumberProduct: function (number) {
			let URL = this.$services.endpoints.GET_PRODUCTS + `?page=${number}`
			if (this.sub !== 'all') {
				URL += `&subscription_id=${this.sub}`
			}
			this.$store.dispatch('house/customGetRequest', {URL})
				.then(res => {
					this.vloading = false
					this.products = res.data.data
				}).catch(err => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		searchInputFilter: debounce(function () {
			this.changeAppName()
		}, 1000),
		computeFilter() {
			let URL = ''
			if (this.sub) {
				URL += `&subscription_id=${this.sub}`
			}
			if (this.filterParams.status) {
				URL += `&${this.filterParams.status}`
			}
			if (this.filterParams.app_id) {
				URL += `&${this.filterParams.app_id}`
			}
			if (this.filterParams.search) {
				URL += `&search=${this.filterParams.search}`
			}
			if (this.filterParams.accounting) {
				URL += `&${this.filterParams.accounting}`
			}
			if (this.sortValue) {
				URL += `&ordering=${this.sortValue}`
			}
			return URL
		},
	},
	mounted() {
		this.getSubscriptionList()
		this.getProduct()
	},
	created() {
		this.appList = []
		for (const [key, value] of Object.entries(APPID)) {
			let db = {key: key.replace("_", ' '), value: `subscription__app_type=${value}`}
			this.appList.push(db)
		}
	}
}
</script>

<style scoped>
.custom-input {

	height: 29px !important;
	font-size: 13px !important;
	font-weight: 400;

}
</style>
