<template>
	<div class="app-main__inner">
		<div class="continer" id="enable_fullscreen">
			<!--			-->
			<div class="bg-white p-3 mt-3 rounded position-relative">
				<span class="position-absolute full-screen cursor-point" id="enable-full-screen"><i
					class="el el-icon-full-screen"></i></span>
				<div class="row ">
					<div class="col-md-4">
						<div class="">
							<div class="mr-2">
								<span class="font-semibold">{{ $t('subscription') }}</span>
							</div>
							<div class="w-full">
								<el-select v-model="form.subscription" :placeholder="$t('search')" filterable
										   class="w-full"
										   size="mini"
										   remote
										   :loading="subLoading"
										   :loading-text="$t('loading')"
										   v-on:change="onChangeSubscription"
										   reserve-keyword
										   :remote-method="remoteMethod">
									<el-option
										v-for="(item,key) in subscriptions.results"
										:key="key"
										:label="`(${item.id})  ${item.app_type} - ${item.username}` "
										:value="item.id">
									</el-option>
								</el-select>


							</div>
						</div>
					</div>
					<div class="col-md-3">
						<div class="">
							<div class="mr-2">
								<span class="font-semibold">{{ $t('app') }}</span>
							</div>
							<div class="">
								<span v-if="form.subscription">{{
										form.app_type
									}}</span>
							</div>
						</div>
					</div>
					<div class="col-md-3">
						<div class="">
							<div class="mr-2">
								<span class="font-semibold">{{ $t('storeIdentifier') }}</span>
							</div>
							<div class="">
								<span v-if="form.subscription">{{
										form.store_identifier
									}}</span>
							</div>
						</div>
					</div>
					<div class="col-md-2">
						<div class="">
							<div class="mr-2">
								<span class="font-semibold">{{ $t('element') }}</span>
							</div>

							<div class="w-full">
								<select class="w-full select-field" v-model="form.elementType"
										v-if="form.clientType === 'none'">
									<option>{{ $t('select') }}</option>
									<option v-for="(item,key) in elementTypeDict" :key="key" :value="item.key">{{
											$t(`${item.key}`)
										}}
									</option>
								</select>
								<select class="w-full select-field" v-model="form.elementType"
										v-if="otherClient.includes(form.clientType) === true">
									<option>{{ $t('select') }}</option>
									<option v-for="(item,key) in otherMethods" :key="key" :value="item">{{
											$t(`${item}`)
										}}
									</option>
								</select>

							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<div class="mt-3 flex justify-content-between">
							<span>{{ $t('additionalParameter') }}</span>
							<span>
							<i class="fa fa-chevron-down cursor-pointer" v-if="additionParameterBool === false"
							   v-on:click="openAdditionParameterBool"></i>
							<i class="fa fa-chevron-up cursor-pointer" v-else
							   v-on:click="openAdditionParameterBool"></i>
						</span>
						</div>
					</div>
					<div class="col-md-12 mt-3" v-if="additionParameterBool === true">

						<div class="row">
							<div class="col-md-3">
								<label style="margin-bottom: 0px !important;">{{ $t('date') }}</label>
								<input type="date"
									   :placeholder="$t('pick_date')"
									   v-model="form.date"
									   class="form-input w-full custom-form-control text-xs">
							</div>
							<div class="col-md-3">
								<label style="margin-bottom: 0px !important;">{{ $t('dateRange') }}</label>
								<el-date-picker
									class="w-full text-xs font-light app-input"
									v-model="dateRange"
									type="daterange"
									value-format="yyyy-MM-dd"
									format="yyyy/MM/dd"
									start-placeholder="Startdatum"
									end-placeholder="Slutdatum">
								</el-date-picker>
							</div>
						</div>
						<div class="row mt-4">
							<div class="col-md-12">
								<div class="w-full mb-2">
									<p>{{ $t('optionalParams') }}</p>
								</div>
								<div class="w-full">
									<div class="flex gap-4 pt-2" v-for="(add, i) in additionParams" :key="i">
										<span style="min-width: 90px;width: 20px">
											<div class="w-24">
												<select
													class="w-full form-input custom-form-control text-xs select-field"
													v-model="add.type"
												>
													<option v-for="(item,key) in inputTypeList" :key="key"
															:value="item">{{
															item
														}}
													</option>
												</select>
											</div>
										</span>
										<span style="min-width: 300px;width: 200px">
											<div class="w-full">
												<input type="text" v-model="add.key"
													   :placeholder="$t('key')"
													   class="form-input w-full custom-form-control text-xs">
											</div>
										</span>
										<span style="min-width: 500px;width: 500px">
											<div class="w-full">
												<input :type="add.type" v-model="add.value"
													   v-if="['number','text'].includes(add.type) === true"
													   :placeholder="$t('value')"
													   class="form-input w-full custom-form-control text-xs">
												<textarea class="form-control text-xs" v-model="add.value" rows="3"
														  placeholder="{'key':'value'}"
														  v-else
														  cols="3"></textarea>
											</div>
										</span>
										<span class="">
											<div class="w-full">
										<span class="mr-2 cursor-pointer" v-on:click="onDelete(i)"><i
											class=" iconly-brokenDelete icon-color"></i></span>
											</div>
										</span>
									</div>
								</div>
							</div>
							<div class="col-md-12">
								<button class="p-1 bg-pink-500 text-white text-xs float-right" type="button"
										v-on:click.prevent="addParams">{{ $t('addParams') }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="bg-blue-gray w-full filter-container ">
				<div class="row">
					<div class="col-md-2">
						<label>{{ $t('elementId') }}</label>
						<input type="text"
							   :placeholder="$t('enterElementID')"
							   v-model="form.elementId"
							   class="form-input w-full custom-form-control text-xs">
					</div>
					<div class="col-md-2">
						<label>{{ $t('method') }}</label>
						<select class="w-full form-input custom-form-control select-field" v-model="form.method">
							<option v-for="(item,key) in methodList" :key="key" :value="item">{{
									item
								}}
							</option>
						</select>
					</div>
					<div class="col-md-2">
						<label>{{ $t('client') }}</label>
						<select class="w-full form-input custom-form-control select-field" v-model="form.clientType"
								v-on:change="onChangeClient">
							<option value="none">{{ $t('select') }}</option>
							<option v-for="(item,key) in clientType" :key="key" :value="item.key">{{
									item.name
								}}
							</option>
						</select>
					</div>
					<div class="col-md-4">
						<div class="flex filter-api-btn">
							<button v-on:click="triggerRequest" :disabled="loading"
									style="justify-content: center;"
									class="btn btn-primary mr-3 query-api flex">
								<span style="">{{ $t('queryApi') }} </span>
								<span style="margin-left: 20px;padding-top: 4px;" v-if="loadingClientApi"><svg
									class="animate-spin -ml-1 mr-3  h-3 w-3 text-white"
									xmlns="http://www.w3.org/2000/svg" fill="none"
									viewBox="0 0 24 24">
                                                            <circle class="opacity-25" cx="12" cy="12" r="10"
																	stroke="currentColor" stroke-width="4"></circle>
                                                            <path class="opacity-75" fill="currentColor"
																  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
							</span>
							</button>
							<button href="javascript:void(0)" v-on:click="fetchDataFromAm" :disabled="loading"
									class="btn btn-primary query-local-db flex" style="justify-content: center;">
								<span style="">{{ $t('queryLocalDb') }} </span><span v-if="loadingAmApi"
																					 class="ml-2"
																					 style="padding-top: 4px;"><svg
								class="animate-spin -ml-1 mr-3 h-3 w-3 text-white"
								xmlns="http://www.w3.org/2000/svg" fill="none"
								viewBox="0 0 24 24">
                                                            <circle class="opacity-25" cx="12" cy="12" r="10"
																	stroke="currentColor" stroke-width="4"></circle>
                                                            <path class="opacity-75" fill="currentColor"
																  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg></span>
							</button>
						</div>
					</div>
					<div class="col-md-2">
						<div class="flex filter-api-btn">
						</div>
					</div>
				</div>
			</div>


		</div>
		<div class="row mt-3">
			<div class="col-md-12" v-if="form.elementType && form.clientType === 'none'">
				<div class="w-full table-responsive bg-white table-container">
					<table style="width: 100%;" id="exampledestroy"
						   class="table table-hover table-striped table-bordered table-auto overflow-x-scroll w-full block"
					>
						<thead>
						<tr>
							<th>{{ $t('element') }}</th>
							<th class="text-xs font-bold" v-for="(item,key) in tableHeaders[form.elementType]">
								<span class="iconly-boldProfile">{{ $t(`${item}`) }}</span>
							</th>
							<th>{{ $t('action') }}</th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="(data,i) in amApiData.results">
							<td>{{ $t(`${form.elementType}`) }}</td>
							<td v-for="(item,k) in tableHeaders[form.elementType]"
							>

								<div v-if="item === 'subscription'" class="grid grid-cols-1">
									<p class="py-[2px]"><span class="font-semibold text-xs">{{
											$t('company')
										}}:</span>
										{{
											data[item]['company_name']
										}}</p>
									<p class="py-[2px]"><span class="font-semibold text-xs">{{
											$t('app_type')
										}}:</span>
										{{
											data[item]['app_type']
										}}</p>
								</div>
								<div v-else><span>{{ data[item] }}</span></div>
							</td>
							<td>
								<span class="cursor-pointer" v-on:click="openAmDataSlider('am-data',data)"><i
									class="text-base cursor-pointer iconly-brokenEdit icon-color"></i></span>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
				<div class="col-md-12">
					<div class="flex justify-end">
						<paginate
							:page-count="amApiData.pages"
							:click-handler="amOnChangePage"
							prev-text="<"
							next-text=">"
							:container-class="'pagination'">
						</paginate>
					</div>

				</div>
			</div>
			<div class="col-md-12" v-else-if="form.elementType && form.clientType === 'fortnox'"
				 v-loading="loadingClientApi">
				<div class="table-responsive bg-white table-container">
					<table style="width: 100%;"
						   class="table table-hover table-striped table-bordered table-auto overflow-x-scroll w-full block"
					>
						<thead>
						<tr>
							<th>{{ $t('element') }}</th>
							<th class="text-xs font-bold"
								v-for="(item,key) in fortnoxTableHeader[form.elementType]">
								<span class="iconly-boldProfile">{{ $t(`${item}`) }}</span>
							</th>
							<th>{{ $t('action') }}</th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="(data,i) in clientApiData.results">
							<td>{{ $t(`${form.elementType}`) }}</td>
							<td v-for="(item,k) in fortnoxTableHeader[form.elementType]"
							>

								<div v-if="item === 'subscription'" class="grid grid-cols-1">
									<p class="py-[2px]"><span class="font-semibold text-xs">{{
											$t('company')
										}}:</span>
										{{
											data[item]['company_name']
										}}</p>
									<p class="py-[2px]"><span class="font-semibold text-xs">{{
											$t('app_type')
										}}:</span>
										{{
											data[item]['app_type']
										}}</p>
								</div>
								<div v-else><span>{{ data[item] }}</span></div>
							</td>
							<td>
								<span class="cursor-pointer" v-on:click="openAmDataSlider('am-data',data)"><i
									class="text-base cursor-pointer iconly-brokenEdit icon-color"></i></span>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
				<div class="col-md-12">
					<div class="flex justify-end">
						<paginate
							:page-count="clientApiData.pages"
							:click-handler="clientOnChangePage"
							prev-text="<"
							next-text=">"
							:container-class="'pagination'">
						</paginate>
					</div>

				</div>
			</div>
			<div class="col-md-12" v-else>
				<div class="table-responsive bg-white table-container" v-if="clientApiData.results.length > 0">
					<table style="width: 100%;"
						   class="table table-hover table-striped table-bordered table-auto overflow-x-scroll w-full block"
					>
						<thead>
						<tr>
							<th>{{ $t('element') }}</th>
							<th class="text-xs font-bold"
								v-for="(item,key) in genericHeader" :key="key">
								<span class="iconly-boldProfile">{{ $t(`${item}`) }}</span>
							</th>
							<th>{{ $t('action') }}</th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="(data,i) in clientApiData.results">
							<td>{{ $t(`${form.elementType}`) }}</td>
							<td v-for="(item,k) in genericHeader"
							>
								<div><span>{{ data[item] }}</span></div>
							</td>
							<td>
								<span class="cursor-pointer" v-on:click="openAmDataSlider('am-data',data)"><i
									class="text-base cursor-pointer iconly-brokenEdit icon-color"></i></span>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
				<div class="col-md-12">
					<div class="flex justify-end">
						<paginate
							:page-count="clientApiData.pages"
							:click-handler="clientOnChangePage"
							prev-text="<"
							next-text=">"
							:container-class="'pagination'">
						</paginate>
					</div>

				</div>

			</div>
			<div class="col-md-12" v-if="showMessage === true">
				<div class="w-full">
					<div class="p-2 text-white rounded text-center"
						 :class="{'bg-blue-500': showMessageStatus === 'info','bg-red-500': showMessageStatus === 'danger'}">
						{{ messageInfo }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import Paginate from 'vuejs-paginate'
import {mapGetters} from "vuex"
import {DatePicker} from 'element-ui'
import moment from "moment"
import Vue from "vue";
import debounce from 'lodash/debounce'

window.$ = window.jQuery = require('jquery')
require('./../../assets/jquery-ui/jquery-ui')
require('./../../assets/select2/select2.min')

Vue.use(DatePicker)
export default {
	name: "apiQueries",
	components: {
		VueJsonPretty,
		paginate: Paginate,
	},
	data: function () {
		return {
			subscriptions: {results: []},
			openSubscriptionDown: false,
			amDownloadIcon: false,
			clientDownloadIcon: false,
			showMessage: false,
			showMessageStatus: '',
			messageInfo: 'No data available',
			subLoading: false,
			searchedSubscription: '',
			queryResponse: {},
			queryAmResponse: {},
			inputTypeList: ['number', 'text', 'json'],
			tableHeaders: {
				'invoice': ['number_in_accounting', 'number_in_app', 'subscription', 'date_created'],
				'single_invoice': ['number_in_accounting', 'number_in_app', 'subscription', 'date_created'],
				'order': ['number_in_accounting', 'number_in_app', 'subscription', "notes",
					"total_price",
					"vat_included",
					"currency",
					"terms",
					"customer", "date_created",],
				'single_article': ['number_in_accounting', 'number_in_app', 'subscription', "name",
					"price",
					"stock_quantity",
					"sku",
					"notes"],
				'single_order': ['number_in_accounting', 'number_in_app', 'subscription', "notes",
					"total_price",
					"vat_included",
					"currency",
					"terms",
					"customer", "date_created",],
				'customer': ['number_in_accounting', 'number_in_app', 'subscription',
					"customer_type", "name", "email", "phone",
					"country", "city",],
				'article': ['number_in_accounting', 'number_in_app', 'subscription', "name",
					"price",
					"stock_quantity",
					"sku",
					"notes",],
				'invoice_payment': ['number_in_accounting', 'number_in_app', 'subscription',
					"accounting_invoice_number",
					"amount_paid_in_sek", "payout_id", "payment_date"],
				'voucher': ['number_in_accounting', 'number_in_app', 'subscription',
					"description", "series", "transaction_date", "original_transaction_date", "comments", "currency",
					"order_country", "order_date",],
				'invoice_accrual': ['number_in_accounting', 'number_in_app', 'subscription', "total_amount_in_sek",
					"total_amount_customer_currency",
					"start_date", "end_date",
				],
			},
			additionParams: [],
			form: {
				subscription: '',
				elementType: '',
				elementId: '',
				date: '',
				clientType: 'none',
				method: 'get',
				app_type: '',
				store_identifier: ''
			},
			methodList: ['get', 'post'],
			elementTypeDict: [
				// {name: 'Invoice', key: 'invoice'},
				{
					name: 'Order',
					key: 'order'
				},
				{
					name: 'Voucher',
					key: 'voucher'
				},
				{name: 'Customer', key: 'customer'},
				{name: 'Article', key: 'article'},
				{name: 'Invoice Payment', key: 'invoice_payment'},
				{
					name: 'Invoice Accrual',
					key: 'invoice_accrual'
				},
				{name: 'Single Order', key: 'single_order'},
				// {name: 'Single Invoice', key: 'single_invoice'},
				{name: 'Single Article', key: 'single_article'},
			],
			loading: false,
			loadingAmApi: false,
			genericHeader: [],
			genericData: [],
			loadingClientApi: false,
			loadingPagingAm: false,
			loadingPagingClient: false,
			amApiData: {
				results: [],
				limit: 50,
				page: 1,
				pages: 1,
			},
			clientApiData: {
				results: [],
				limit: 50,
				page: 1,
				pages: 1,
			},
			pseudoElementType: {
				single_order: 'order',
				single_article: 'article',
			},
			clientType: [
				{name: 'Fortnox', key: 'fortnox'},
				{name: 'Visma', key: 'visma'},
			],
			fortnoxMethods: [
				{name: 'All Invoice', key: 'invoice'},
				{name: 'Get invoice by number', key: 'single_invoice'},
				{name: 'All Article', key: 'article'},
				{name: 'Single Article', key: 'single_article'},
				{name: 'All Order', key: 'order'},
				{name: 'Get order by number', key: 'single_order'},
				{name: 'All Contracts', key: 'contract'},
				{name: 'Get Contracts by number', key: 'singe_contract'},
				{name: 'Invoice Payment', key: 'invoice_payment'},
				{name: 'Customers', key: 'customer'},
				{name: 'Get Customer by number', key: 'single_customer'},
				{name: 'Get Customer by name', key: 'single_customer_by_name'},
				{name: 'Get all financial years', key: 'financial_years'},
				{name: 'Get all vouchers', key: 'vouchers'},
				{name: 'All Employee', key: 'employee'},
				{name: 'Get employee by number', key: 'single_employee'},
			],
			fortnoxTableHeader: {
				invoice: ["CustomerName", "CustomerNumber", "DocumentNumber", "DueDate", "InvoiceDate", "OrganisationNumber", "Total", "TotalToPay"],
				single_invoice: ["CustomerName", "CustomerNumber", "DocumentNumber", "DueDate", "InvoiceDate", "OrganisationNumber", "Total", "TotalToPay"],
				article: ["ArticleNumber", "Description", "SalesPrice", "PurchasePrice", "QuantityInStock", "Active",],
				single_article: ["ArticleNumber", "Description", "SalesPrice", "PurchasePrice", "QuantityInStock", "Active",],
				order: ['DocumentNumber', 'CustomerNumber', 'CustomerName', 'OrderDate', 'Total', 'TotalToPay'],
				single_order: ['DocumentNumber', 'CustomerNumber', 'CustomerName', 'OrderDate', 'Total', 'TotalToPay'],
				contract: ["CustomerName", "CustomerNumber", "DocumentNumber", "ContractDate", "PeriodStart", "PeriodEnd", "Total", "TotalToPay",],
				singe_contract: ["CustomerName", "CustomerNumber", "DocumentNumber", "ContractDate", "PeriodStart", "PeriodEnd", "Total", "TotalToPay",],
				invoice_payment: ["Number", "InvoiceCustomerName", "InvoiceCustomerNumber", "InvoiceNumber", "InvoiceTotal", "PaymentDate",],
				customer: ['Name', 'CustomerNumber', 'OrganisationNumber', 'Phone1', 'Email', 'ZipCode', 'Country', 'City', 'Currency', 'Active'],
				single_customer: ['Name', 'CustomerNumber', 'OrganisationNumber', 'Phone1', 'Email', 'ZipCode', 'Country', 'City', 'Currency', 'Active'],
				single_customer_by_name: ['Name', 'CustomerNumber', 'OrganisationNumber', 'Phone1', 'Email', 'ZipCode', 'Country', 'City', 'Currency', 'Active'],
				financial_years: ['Id', 'FromDate', 'ToDate', 'AccountingMethod', 'accountCharts',],
				vouchers: ['Comments', 'Description', 'ReferenceNumber', 'ReferenceType', 'TransactionDate', 'VoucherNumber', 'VoucherSeries', 'Year', 'ApprovalState'],
				employee: ['EmployeeId', 'PersonalIdentityNumber', 'FirstName', 'LastName', 'FullName', 'Address1', 'Address2', 'PostCode', 'City', 'Country', 'Phone1', 'Phone2', 'Email'],
				single_employee: ['EmployeeId', 'PersonalIdentityNumber', 'FirstName', 'LastName', 'FullName', 'Address1', 'Address2', 'PostCode', 'City', 'Country', 'Phone1', 'Phone2', 'Email'],
			},
			vismaMethods: [
				{name: 'Get all fiscal year', key: 'fiscal_year'},
			],
			dateRange: [],
			additionParameterBool: false,
			otherClient: [],
			otherMethods: [],
			CLIENT_METHOD_LIST: {},
			pageNo: null
		}
	},
	methods: {
		addParams: function () {
			this.additionParams.push({key: '', value: '', type: 'text'})
		},
		onDelete: function (index) {
			this.additionParams.splice(index, 1)
		},
		onChangeSubscription: function () {
			let sub = this.subscriptions.results.filter(item => item.id === this.form.subscription)
			if (sub.length > 0) {
				this.form.app_type = sub[0].app_type
				this.form.store_identifier = sub[0].store_identifier
			}
		},
		openAmDataSlider: function (type, data) {
			window.Bus.$emit('open-am-queries', {type, data})
		},
		showNotification: function (status, message) {
			this.messageInfo = message
			this.showMessageStatus = status
			this.showMessage = true
		},
		computeAdditionalParam: function () {
			let URL = ''
			let payload = {}
			this.additionParams.map(item => {
				if (item.key && item.value) {
					if (item.type !== 'json') {
						URL += `&${item.key}=${item.value}`
						payload[item.key] = item.value
					}
					if (item.type === 'json') {
						try {
							payload[item.key] = JSON.parse(item.value)
						} catch (e) {
							console.log(e)
						}
					}

				}
			})
			return {'param': URL, 'payload': payload}
		},
		async getSubscriptionList() {
			let url = 'get_all_fields=True&page_size=100'
			if (this.searchedSubscription) {
				url += `&search=${this.searchedSubscription}`
			}
			this.$store.dispatch('house/getAllSubscription', {data: url})
				.then(resp => {
					this.subscriptions.results = resp.data.results
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getSubscriptionList(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		openAdditionParameterBool: function () {
			this.additionParameterBool = !this.additionParameterBool
		},
		fetchDataFromAm: function () {
			this.loading = true
			this.loadingAmApi = true
			this.cleanDisplay('am')
			this.cleanDisplay('client')
			this.showMessage = false
			let URL = `${this.$services.endpoints.INHOUSE_AM_API}?limit=20`
			if (!this.form.subscription) {
				this.loading = false
				this.loadingAmApi = false
				this.$services.helpers.notification('Kindly select a subscription', 'error', this)
				return false
			}
			URL += `&subscription_id=${this.form.subscription}`

			if (this.form.elementType) {
				if (Object.keys(this.pseudoElementType).includes(this.form.elementType) === true) {
					URL += `&resource_type=${this.pseudoElementType[this.form.elementType]}`
				} else {
					URL += `&resource_type=${this.form.elementType}`
				}
			}
			if (this.form.elementId) {
				URL += `&element_id=${this.form.elementId}`
			}
			if (this.dateRange && this.dateRange.length > 0) {
				URL += `&date_from=${this.dateRange[0]}&date_to=${this.dateRange[1]}`
			}
			this.queryAmResponse = {}
			this.$store.dispatch('house/customGet', {URL}).then(resp => {
				this.loading = false
				this.loadingAmApi = false
				this.amApiData.results = resp.data.data
				this.amApiData.page = resp.data.page
				this.amApiData.pages = resp.data.pages
				this.amApiData.limit = resp.data.limit
			}).catch(err => {
				this.loading = false
				this.loadingAmApi = false
				this.showNotification(false, err.response.data)
			})
		},
		amOnChangePage: function (number) {
			let URL = `${this.$services.endpoints.INHOUSE_AM_API}?page=${number}&limit=${20}`
			if (!this.form.subscription) {
				this.loading = false
				this.loadingAmApi = false
				this.$services.helpers.notification('Kindly select a subscription', 'error', this)
				return false
			}
			URL += `&subscription_id=${this.form.subscription}`

			if (this.form.elementType) {
				if (Object.keys(this.pseudoElementType).includes(this.form.elementType) === true) {
					URL += `&resource_type=${this.pseudoElementType[this.form.elementType]}`
				} else {
					URL += `&resource_type=${this.form.elementType}`
				}
			}
			if (this.form.elementId) {
				URL += `&element_id=${this.form.elementId}`
			}
			if (this.dateRange && this.dateRange.length > 0) {
				URL += `&date_from=${this.dateRange[0]}&date_to=${this.dateRange[1]}`
			}
			if (this.computeAdditionalParam()) {
				URL += this.computeAdditionalParam()
			}
			this.queryAmResponse = {}
			this.$store.dispatch('house/customGet', {URL}).then(resp => {
				this.loading = false
				this.loadingAmApi = false
				this.amApiData.results = resp.data.data
				this.amApiData.page = resp.data.page
				this.amApiData.pages = resp.data.pages
				this.amApiData.limit = resp.data.limit
			}).catch(err => {
				this.loading = false
				this.loadingAmApi = false
				this.queryAmResponse = err.response.data
				this.queryResponse = err.response.data
			})
		},
		clientOnChangePage: function (action) {
			this.loading = true
			this.loadingClientApi = true
			this.fetchDataFromClient(action)
			// let URL = `${this.$services.endpoints.INHOUSE_CLIENT_API}?page=${action}&limit=${this.clientApiData.limit}`
			// if (!this.form.subscription) {
			// 	this.loading = false
			// 	this.loadingClientApi = false
			// 	this.$services.helpers.notification('Kindly select a subscription', 'error', this)
			// 	return false
			// }
			// URL += `&subscription_id=${this.form.subscription}`
			// if (this.form.clientType === 'fortnox' && this.form.elementType === 'invoice_payment') {
			// 	if (this.form.elementId === '') {
			// 		this.loading = false
			// 		this.loadingClientApi = false
			// 		return this.$services.helpers.notification('Element ID is required', 'error', this)
			// 	}
			// }
			// if (this.form.elementType.includes('single_') === true) {
			// 	if (this.form.elementId === '') {
			// 		this.loading = false
			// 		this.loadingClientApi = false
			// 		return this.$services.helpers.notification('Element ID is required', 'error', this)
			// 	}
			// }
			// if (this.form.elementType === 'vouchers') {
			// 	if (this.form.date === '' || this.form.elementId === '') {
			// 		this.loading = false
			// 		this.loadingClientApi = false
			// 		return this.$services.helpers.notification('Element ID (Voucher series) and Date is required', 'error', this)
			// 	}
			// }
			// if (this.form.elementType) {
			// 	URL += `&resource_type=${this.form.elementType}`
			// }
			// if (this.form.clientType !== 'none') {
			// 	URL += `&client_type=${this.form.clientType}`
			// }
			// if (this.form.elementId) {
			// 	URL += `&element_id=${this.form.elementId}`
			// }
			// let additionalParameter = ['date']
			// additionalParameter.map(item => {
			// 	if (['date'].includes(item)) {
			// 		if (this.form[item]) {
			// 			URL += `&${item}=${moment(this.form[item]).format("YYYY-MM-DD")}`
			// 		}
			// 	}
			//
			// })
			// if (this.dateRange && this.dateRange.length > 0) {
			// 	URL += `&date_from=${this.dateRange[0]}&date_end=${this.dateRange[1]}`
			// }
			// this.$store.dispatch('house/customGet', {URL}).then(resp => {
			// 	this.loading = false
			// 	this.loadingClientApi = false
			// 	this.loading = false
			// 	this.outputClientData(resp)
			// }).catch(err => {
			// 	this.loading = false
			// 	this.loadingClientApi = false
			// 	this.queryResponse = err.response.data
			// })
		},
		computeExternalQuery: function () {
			let URL = `?limit=${this.clientApiData.limit}`
			let payload = {'limit': this.clientApiData.limit}
			if (!this.form.subscription) {
				this.loading = false
				this.loadingClientApi = false
				this.$services.helpers.notification('Kindly select a subscription', 'error', this)
				return false
			}
			URL += `&subscription_id=${this.form.subscription}`
			payload['subscription_id'] = this.form.subscription
			if (this.form.clientType === 'fortnox' && this.form.elementType === 'invoice_payment') {
				if (this.form.elementId === '') {
					this.loading = false
					this.loadingClientApi = false
					return this.$services.helpers.notification('Element ID is required', 'error', this)
				}
			}
			if (['fortnox', 'visma'].includes(this.form.clientType) === true) {
				if (this.form.elementType.includes('single_') === true) {
					if (this.form.elementId === '') {
						this.loading = false
						this.loadingClientApi = false
						return this.$services.helpers.notification('Element ID is required', 'error', this)
					}
				}
				if (this.form.elementType.includes('by_id') === true || this.form.elementType.includes('by_reference') === true) {
					if (this.form.elementId === '') {
						this.loading = false
						this.loadingClientApi = false
						return this.$services.helpers.notification('Element ID is required', 'error', this)
					}
				}
			}
			if (this.form.elementType === 'vouchers') {
				if (this.form.date === '' || this.form.elementId === '') {
					this.loading = false
					this.loadingClientApi = false
					return this.$services.helpers.notification('Element ID (Voucher series) and Date is required', 'error', this)
				}
			}
			if (this.form.elementType) {
				URL += `&resource_type=${this.form.elementType}`
				payload['resource_type'] = this.form.elementType
			}
			if (this.form.clientType !== 'none') {
				URL += `&client_type=${this.form.clientType}`
				payload['client_type'] = this.form.clientType
			}
			if (this.form.elementId) {
				URL += `&element_id=${this.form.elementId}`
				payload['element_id'] = this.form.elementId
			}
			let additionalParameter = ['date']
			additionalParameter.map(item => {
				if (['date'].includes(item)) {
					if (this.form[item]) {
						URL += `&${item}=${moment(this.form[item]).format("YYYY-MM-DD")}`
						payload[item] = moment(this.form[item]).format("YYYY-MM-DD")
					}
				}

			})
			if (this.dateRange && this.dateRange.length > 0) {
				URL += `&date_from=${this.dateRange[0]}&date_end=${this.dateRange[1]}`
				payload['date_from'] = this.dateRange[0]
				payload['date_end'] = this.dateRange[1]
			}
			let addon = this.computeAdditionalParam()
			if (addon.param) {
				URL += addon.param
			}
			if (Object.keys(addon.payload).length > 0) {
				payload = {...payload, ...addon.payload}
			}
			return {'URL': URL, 'payload': payload}
		},
		downloadFile: function (tab, content_type = 'json') {
			let results = []
			if (tab === 'am') {
				results = this.amApiData.results
			} else {
				results = this.clientApiData.results
			}
			if (results.length <= 0) {
				this.$services.helpers.notification('', 'error', this)
				return
			}
			let d_type = 'text/plain'
			if (content_type === 'json') {
				d_type = 'text/json'
			}
			const blob = new Blob([JSON.stringify(results)], {type: d_type});
			const link = document.createElement("a");
			if (content_type === 'json') {
				link.download = `${this.form.subscription.app_type}.json`;
			} else {
				link.download = `${this.form.subscription.app_type}.txt`;
			}
			link.href = window.URL.createObjectURL(blob);
			link.dataset.downloadurl = [content_type, link.download, link.href].join(":");

			const evt = new MouseEvent("click", {
				view: window,
				bubbles: true,
				cancelable: true,
			});

			link.dispatchEvent(evt);
			link.remove()


		},
		cleanDisplay: function (tab) {
			if (tab === 'am') {
				this.amApiData = {
					results: [],
					limit: 10,
					page: 1,
					pages: 1,
				}
				this.queryAmResponse = {}

			} else {
				this.clientApiData = {
					results: [],
					limit: 10,
					page: 1,
					pages: 1,
				}
				this.queryResponse = {}
			}
		},
		triggerRequest: function () {
			this.fetchDataFromClient(null)
		},
		fetchDataFromClient: function (pageNum = null) {
			this.loading = true
			this.loadingClientApi = true
			this.showMessage = false
			this.cleanDisplay('client')
			let URL = `${this.$services.endpoints.INHOUSE_CLIENT_API}`
			let computedData = this.computeExternalQuery()
			//
			// let payload = {'limit': this.clientApiData.limit}
			// if (!this.form.subscription) {
			// 	this.loading = false
			// 	this.loadingClientApi = false
			// 	this.$services.helpers.notification('Kindly select a subscription', 'error', this)
			// 	return false
			// }
			// URL += `&subscription_id=${this.form.subscription}`
			// payload['subscription_id'] = this.form.subscription
			// if (this.form.clientType === 'fortnox' && this.form.elementType === 'invoice_payment') {
			// 	if (this.form.elementId === '') {
			// 		this.loading = false
			// 		this.loadingClientApi = false
			// 		return this.$services.helpers.notification('Element ID is required', 'error', this)
			// 	}
			// }
			// if (['fortnox', 'visma'].includes(this.form.clientType) === true) {
			// 	if (this.form.elementType.includes('single_') === true) {
			// 		if (this.form.elementId === '') {
			// 			this.loading = false
			// 			this.loadingClientApi = false
			// 			return this.$services.helpers.notification('Element ID is required', 'error', this)
			// 		}
			// 	}
			// 	if (this.form.elementType.includes('by_id') === true || this.form.elementType.includes('by_reference') === true) {
			// 		if (this.form.elementId === '') {
			// 			this.loading = false
			// 			this.loadingClientApi = false
			// 			return this.$services.helpers.notification('Element ID is required', 'error', this)
			// 		}
			// 	}
			// }
			//
			// if (this.form.elementType === 'vouchers') {
			// 	if (this.form.date === '' || this.form.elementId === '') {
			// 		this.loading = false
			// 		this.loadingClientApi = false
			// 		return this.$services.helpers.notification('Element ID (Voucher series) and Date is required', 'error', this)
			// 	}
			// }
			// if (this.form.elementType) {
			// 	URL += `&resource_type=${this.form.elementType}`
			// 	payload['resource_type'] = this.form.elementType
			// }
			// if (this.form.clientType !== 'none') {
			// 	URL += `&client_type=${this.form.clientType}`
			// 	payload['client_type'] = this.form.clientType
			// }
			// if (this.form.elementId) {
			// 	URL += `&element_id=${this.form.elementId}`
			// 	payload['element_id'] = this.form.elementId
			// }
			// let additionalParameter = ['date']
			// additionalParameter.map(item => {
			// 	if (['date'].includes(item)) {
			// 		if (this.form[item]) {
			// 			URL += `&${item}=${moment(this.form[item]).format("YYYY-MM-DD")}`
			// 			payload[item] = moment(this.form[item]).format("YYYY-MM-DD")
			// 		}
			// 	}
			//
			// })
			// if (this.dateRange && this.dateRange.length > 0) {
			// 	URL += `&date_from=${this.dateRange[0]}&date_end=${this.dateRange[1]}`
			// 	payload['date_from'] = this.dateRange[0]
			// 	payload['date_end'] = this.dateRange[1]
			// }
			// let addon = this.computeAdditionalParam()
			// if (addon.param) {
			// 	URL += addon.param
			// }
			// if (Object.keys(addon.payload).length > 0) {
			// 	payload = {...payload, ...addon.payload}
			// }
			if (this.form.method === 'get') {
				URL += computedData.URL
				if (pageNum) {
					URL += `&page=${pageNum}`
				}
				this.$store.dispatch('house/customGet', {URL}).then(resp => {
					this.loading = false
					this.loadingClientApi = false
					this.loading = false
					this.outputClientData(resp)


				}).catch(err => {
					this.loading = false
					this.loadingClientApi = false
					this.queryResponse = err.response.data
					this.showNotification('danger', err.response.data)
				})
			} else {
				let data_load = computedData.payload
				if (pageNum) {
					data_load['page'] = pageNum
				}
				this.$store.dispatch('house/customPostRequest', {URL: URL, data: data_load}).then(resp => {
					this.loading = false
					this.loadingClientApi = false
					this.loading = false
					this.outputClientData(resp)


				}).catch(err => {
					this.loading = false
					this.loadingClientApi = false
					this.queryResponse = err.response.data
					this.showNotification('danger', err.response.data)
				})
			}

		},
		outputClientData: function (resp) {
			if (resp.data.results.length <= 0) {
				// no data to display
				this.showNotification('info', 'No data to display')
				return false
			}
			if (['fortnox', 'visma'].includes(this.form.clientType) === true) {
				this.clientApiData.results = resp.data.results
				this.clientApiData.limit = resp.data.limit
				this.clientApiData.page = resp.data.page
				this.clientApiData.pages = resp.data.pages
			} else {
				let first_data = resp.data.results[0]
				this.genericHeader = Object.keys(first_data)
				this.clientApiData.results = resp.data.results
				this.clientApiData.limit = resp.data.limit
				this.clientApiData.page = resp.data.page
				this.clientApiData.pages = resp.data.pages
			}
		},
		remoteMethod: debounce(function (query) {
			if (query !== '') {
				this.subLoading = true
				let url = `get_all_fields=True&page_size=100&search=${query}`
				this.$store.dispatch('house/getAllSubscription', {data: url})
					.then(resp => {
						this.subscriptions.results = resp.data.results
						this.subLoading = false
					}).catch((err) => {
					this.subLoading = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
								})
								.catch(_ => {
									// console.log('error response')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		}, 300),
		onChangeClient: function () {
			if (Object.keys(this.CLIENT_METHOD_LIST).includes(this.form.clientType)) {
				this.otherMethods = Object.keys(this.CLIENT_METHOD_LIST[this.form.clientType])
			}
		},
		fetchClient: function () {
			let URL = `${this.$services.endpoints.INHOUSE_CLIENT_LIST_API}?limit=${this.clientApiData.limit}`
			this.$store.dispatch('house/customGet', {URL}).then(res => {
				let data = res.data.data
				this.clientType = []
				Object.keys(data).map(key => {
					this.clientType.push({name: key.toLocaleUpperCase(), key: key},)
					this.otherClient.push(key)
				})
				this.CLIENT_METHOD_LIST = data
			})
		}
	},
	computed: {
		...mapGetters({
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
		})
	},
	mounted() {
		let self = this
		window.$('#subscription-select').select2()
		window.$('#element-type').select2()
		window.$('#client-type').select2()
		window.$(document.body).on('change', '#subscription-select', function () {
			let subscription = self.subscriptions.results.filter(item => item.id === parseInt(this.value))
			if (subscription.length > 0) {
				self.form.subscription = subscription[0]
			}
		});
		window.$(document.body).on('click', '#enable-full-screen', function () {
			let elem = document.getElementById("enable_fullscreen");
			if (elem.requestFullscreen) {
				elem.requestFullscreen();
			} else if (elem.webkitRequestFullscreen) { /* Safari */
				elem.webkitRequestFullscreen();
			} else if (elem.msRequestFullscreen) { /* IE11 */
				elem.msRequestFullscreen();
			}
		})
		window.$(document.body).on('change', '#element-type', function () {
			self.form.elementType = this.value
		});
		window.$(document.body).on('change', '#client-type', function () {
			self.form.elementType = this.value
		});
		this.getSubscriptionList()
		this.fetchClient()

	},
	created() {
		// this.otherClient = Object.keys(CLIENT_METHOD_LIST)
		// Object.keys(CLIENT_METHOD_LIST).map(item => {
		// 	this.clientType.push({name: item.toLocaleUpperCase(), key: item})
		// })
	}
}
</script>

<style scoped>
.btn-primary {
	color: #583EF9;
	background: #ffffff;
	border: 1px solid #583EF9;
}

.input-container {
	width: 20%;
}

.input-search-icon {
	top: 4px;
	left: 4px;
}

.form-input {
	padding-left: 23px;
}

.select-field:focus-visible {
	outline: 1px solid transparent;
}

.bg-blue-gray {
	background: #E4EBF4;
}

.filter-container {
	padding: 16px;
}

.custom-form-control {
	height: 34px;
}

.query-api {
	background: #1ED050;
	color: #ffffff;
	border: 1px solid #ffffff;
	height: 34px;
	width: 45%;
	border-radius: 5px;
}

.query-local-db {
	background: #EF5287;
	color: #ffffff;
	border: 1px solid #ffffff;
	height: 34px;
	width: 45%;
	border-radius: 5px;
}

.filter-api-btn {
	margin-top: 28px;
}

.json-editor {
	background: #F4F4F4;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 3px;
}

.json-header {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
}

.full-screen {
	right: 9px;
	top: 1px;
}

.form-input {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	/* background-color: #fff; */
	/* border-color: #e2e8f0; */
	border-width: 1px;
	border-radius: 0.25rem;
	padding-top: 2px !important;
	padding-right: 0.75rem;
	padding-bottom: 2px !important;
	padding-left: 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
}

.subscription-select {
	border-top: 1px solid transparent;
	border-left: 1px solid transparent;
	border-radius: 0px;
	border-right: 1px solid transparent;
	padding-left: 4px;
	padding-right: 4px;
}

@media only screen and (max-width: 600px) {
	.search-container {
		width: 109%;
	}

	.input-container {
		width: 63%;
	}
}
</style>
